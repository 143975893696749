import { createSlice } from "@reduxjs/toolkit";

const initialValues = {
    userOrder: null,
    subTotal: 0,
    coupon: 0,
    delivery: 0,
    total: 0,
    price: 0,
    discounted: 0,
    editMode: false,
    deliveryPrice: null
};

export const ordersSlice = createSlice({
    name: "orders",
    initialState: initialValues,
    reducers: {
        stopCall: (state, action) => {
            state.error = null;
            state.listLoading = false;
            state.actionsLoading = false;
        },
        setUserOrder: (state, action) => {
            state.userOrder = action.payload;
        },
        setOrderPrice: (state, action) => {
            state.subTotal = action.payload.subTotal;
            state.coupon = action.payload.coupon;
            state.delivery = action.payload.delivery;
            state.total = action.payload.total;
            state.price = action.payload.price;
            state.discounted = action.payload.discounted;
        },
        setEditMode: (state, action) => {
            state.editMode = action.payload;
        },
        setDeliveryPrice: (state, action) => {
            state.deliveryPrice = action.payload;
        },
    }
});
