import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { getUserOrder } from "../api/orders";
import Loader from "./Loader";
import moment from "moment";
import { getFormattedPrice } from "../utils";
import { useQuery } from "../hooks/useQuery";
import DeliverTo from "./DeliverTo";
import NeedHelp from "./NeedHelp";

export default function PreviewOrder() {

    let { id } = useParams();
    const query = useQuery();

    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [deliveryDate, setDeliveryDate] = useState(null);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState(null);

    useEffect(() => {
        onLoad();
    }, []);

    async function onLoad() {
        const customer = query.get("customer");
        const res = await getUserOrder(id, customer);

        if (res.data !== null) {
            setData(res.data);
            getOrderProducts(res.data);
            setLoading(false);
        } else {
            history.push("/404");
        }
    }

    function getOrderProducts(deliveryData) {
        const products = [];

        if (deliveryData.order_deliveries_details) {
            deliveryData.order_deliveries_details.forEach(element => {
                products.push({ ...element.products, total: element.total, quantity: element.quantity });
            });
        }

        setProducts(products);
        setDeliveryDate(moment(deliveryData.delivery_date).format("DD/MM/YYYY"));
        setTotal(deliveryData.price);
    }

    function getUserInfo(order) {
        const user = {
            name: "-",
            region: "-",
            address: "-",
            phoneNo: "-",
            accNumber: "-",
            area: "-",
            houseNo: "-",
            extra_info: "-"
        };

        if (order && order.customers) {
            user.name =
                (order.customers.firstname || "-") +
                " " +
                (order.customers.lastname || "-");
            user.region = order.customers.regions.name || "-";
            user.area = order.customers.regions_areas.name || "-";
            user.address =
                (order.customers.extra_info || "-") +
                ", " +
                (order.customers.house_number || "-");
            user.phoneNo = order.customers.phone || "-";
            user.accNumber = order.customers.accNumber || "-";
            user.houseNo = order.customers.house_number || '';
            user.extra_info = order.customers.extra_info || '';
        }

        return user;
    }

    if (loading == true) {
        return <Loader />
    }

    const userInfo = getUserInfo(data);

    return (
        <>
            <div className='section-dashboard'>
                <div className='w-embed'>
                    <style
                        dangerouslySetInnerHTML={{
                            __html:
                                '\n.header__light .headerlogo__text{\ncolor:#026D60;\n}\n.header__light .headercta.is--filled{\nbackground-color:#026D60;\nborder-color:#026D60;\n}\n.header__light .headercta.is--outline{\nborder-color:black;\ncolor:black;\n}\n.header__light .headermenu__link{\ncolor:black;\n}\n.header__light .footer__copylink{\ncolor:black !important;\nopacity:1;\n}\n.btn-shine::after {\n    top: 0;\n    display: block;\n    content: "";\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    -webkit-transform: translateX(-100%);\n    transform: translateX(-100%);\n    background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(rgba(255, 255, 255, .5)), to(transparent));\n    background: linear-gradient(\n90deg, transparent, rgba(255, 255, 255, .5), transparent);\n    -webkit-animation: loading .4s infinite;\n    animation: loading 1s infinite;\n}\n      @keyframes loading {\n        100% {\n          -webkit-transform: translateX(100%);\n          transform: translateX(100%)\n        }\n      }\n.slick-next, .slick-prev {\n    width: 40px;\n    height: 40px;\n    border-radius: 50%;\n    background-color: #fff;\n    box-shadow: 0 2px 8px 0 rgb(51 60 63 / 22%);\n    z-index: 11;\n}\n.overflow-y{\noverflow-y:auto !important;\n}\n.suggest-grid .slick-next, .suggest-grid .slick-prev {\n    width: 40px;\n    height: 40px;\n    border-radius: 50%;\n    background-color: #fbaf3f;\n    }\n    .suggest-grid .slick-disabled{\n    display:none !important;\n    }\n'
                        }}
                    />
                </div>
                <div className="header_navigation is-shop">
                    <div className="main_dashboardcontainer">
                        <div className="header__inner header__light">
                            <a href="#" className="headerlogo w-inline-block">
                                <div className="headerlogo__text">Kiondo Market</div>
                            </a>
                            <div className="header__ctawrap">
                                <Link className="headercta is--filled w-button" to="/login">Login</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="shop-column" style={{ marginTop: 30 }}>
                    <div className='shop-column-left'>
                        <div className='dashboard__menu-container'>
                            <div className='ordeer_summary-wrao'>
                                <div className='order-summary-pricewrap'>

                                    <div style={{ display: 'flex' }}>
                                        <div className='product-card-title is--addresss is-m-middle'>
                                            Subtotal
                                        </div>
                                        <div className='product-card-price is--cart  is-m-middle' style={{ textAlign: 'right', width: '100%' }}>
                                            {getFormattedPrice(total.subTotal)}
                                        </div>
                                    </div>


                                    <div style={{ display: 'flex' }}>
                                        <div className='product-card-title is--addresss is-m-middle'>
                                            Delivery/Packaging
                                        </div>
                                        <div className='product-card-price is--cart  is-m-middle' style={{ textAlign: 'right', width: '100%' }}>
                                            {getFormattedPrice(total.delivery)}
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', color: 'red' }}>
                                        <div className='product-card-title is--addresss is-m-middle' style={{ color: 'red' }}>
                                            Coupon
                                        </div>
                                        <div className='product-card-price is--cart  is-m-middle' style={{ textAlign: 'right', width: '100%', color: 'red' }}>
                                            -{getFormattedPrice(total.coupon)}
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex' }}>
                                        <div className='product-card-title is--addresss is-m-middle' style={{ color: 'red' }}>
                                            Discounted
                                        </div>
                                        <div className='product-card-price is--cart  is-m-middle' style={{ textAlign: 'right', width: '100%', color: 'red' }}>
                                            -{getFormattedPrice(total.discounted)}
                                        </div>
                                    </div>

                                    <div className='product-card-title is--addresss is-m-middle' style={{ marginTop: 50 }}>
                                        <hr />
                                        Total
                                    </div>
                                    <div className='product-card-price is--cart is--grandtotal is--big is-m-middle' style={{ textAlign: 'right' }}>
                                        {getFormattedPrice(total.total)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='shop-column-right'>
                        <div className='shop__contentblock is--single'>
                            <div className='shop__content-tabswrap'>
                                <div className='shop__sectiontitle-big'>Your box for delivery date {deliveryDate}</div>
                            </div>
                            <div className="upcoming-ordercontainer">
                                <div className='w-layout-grid upcoming-order-grid mt-50  is-slider-upcoming-order'>
                                    {products.map((m, i) => {
                                        let total = parseFloat(m.price) * m.quantity;
                                        let discount = 0;

                                        if (m && m.product_discounts) {
                                            discount = (total / 100) * m.product_discounts.discount_percentage;
                                        }

                                        total = total - discount;

                                        return (
                                            <div className='product-card is--cart isb-0'>
                                                <div className='product-card-content is--cart'>
                                                    <img
                                                        src={`${m["image"]}`}
                                                        loading="lazy"
                                                        sizes="(max-width: 479px) 50px, 60px"
                                                        srcSet={`${m["image"]} 500w, ${m["image"]} 800w, ${m["image"]} 1080w, ${m["image"]} 3300w`}
                                                        alt
                                                        className="product-card-image is--cart"
                                                    />
                                                    <div className='product-card-title is--cart'>
                                                        {m["name"]}
                                                    </div>
                                                    <div className='flex-h mt-10 is--cart'>
                                                        <div className='product-card-title is--qty is--cart'>
                                                            {m["short_description"]}
                                                        </div>
                                                        <div className='product-card-title is--qty is--cart'>
                                                            {m["units_of_measure"]["name"]}
                                                        </div>
                                                    </div>
                                                </div>
                                                <span style={{ fontSize: "small", marginTop: 10, marginBottom: 10 }} className="cartProductPrice">
                                                    x{m.quantity} * {getFormattedPrice(m.price)}
                                                </span>
                                                <div className='product-card-pricerow is--cart is--100 is-auto'>
                                                    <div className='flex-h is-100 mt-10 is-auto'>
                                                        <div className='flex-v is--cart is-auto'>
                                                            <div className='product-card-offertag is--cart'>
                                                                {m["Product_discount_id"] && <div className="product-card-offertag is--cart">{m["product_discounts"]["discount_percentage"]}% OFF</div>}
                                                            </div>
                                                            <div className='product-card-price is--cart'>{getFormattedPrice(total)}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className="accountStractureMB accountStracture" style={{ marginTop: 30 }}>
                            <div>
                                <DeliverTo userInfo={userInfo} />
                            </div>
                            <div className="flexColumn marginBottom48">
                                <div>
                                    {" "}
                                    <NeedHelp />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </>
    );
}
