import React, { useEffect, useState } from "react";
import { fetchFamiliesAndRegions, mailChimpSubscribe } from "../api";

export default function SubscribeFooter({ type = 'white' }) {

    const [regions, setRegions] = useState([]);
    const [region, setRegion] = useState(null);
    const [loginResponse, setLoginResponse] = useState(null);
    const [email, setEmail] = useState(null);
 
    useEffect(() => {
        fetchFamiliesAndRegions().then(res => {
            if (res.success) {
                setRegions(res.regions);
            }
        })
    }, []);
 
    async function subscribe() {
        if (email && region) {
            const result = await mailChimpSubscribe({ email, region });
             
            if (result.success === true) {
                setLoginResponse('success');
            } else {
                setLoginResponse('error');
            }
        }
    }

    return <>
        <div className="footer-newletter">
            <div className={`footer-menu-title footer-newsletter-title ${type == 'white' ? 'is--dark' : ''}`}>
                Subscribe to stay in the loop
            </div>
            <div className={`benefit__form-form w-form ${type == 'white' ? '' : 'is--green'}`}>
                <form
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                    className="benefit__forminner"
                >
                    <div className="input__wrapper">
                        <input
                            type="text"
                            className={`form__field padding-left10 is--footer  w-input ${type == 'white' ? 'is--light' : ''}`}
                            maxLength={256}
                            name="name-2"
                            data-name="Name 2"
                            placeholder="Your Email Address"
                            id="name-2"
                            required
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value)
                                setLoginResponse(null);
                            }}
                        />
                        <img
                            src="images/email_icon.svg"
                            loading="lazy"
                            alt
                            className="input__absolute_img"
                        />
                    </div>
                    <div className="input__wrapper mb-10">
                        <select
                            id="field"
                            name="field"
                            required
                            className={`form__field padding-left10 is--footer is--select w-select ${type == 'white' ? 'is--light' : ''}`}
                            value={region} 
                            onChange={(val) => {
                                setRegion(val.target.value)
                                setLoginResponse(null);
                            }}
                        >
                             <option value={null}>Your Location</option>
                            {
                                regions.map((m, i) => <option value={m["id"]}>{m["name"]}</option>)
                            }
                        </select>
                        <img
                            src="images/location__icon.svg"
                            loading="lazy"
                            alt
                            className="input__absolute_img"
                        />
                    </div>
                    <div className="button__wrap is--100 mt-20">
                        <a
                            href="#"
                            className="button__large is--coloryellow is-100 small w-button"
                            onClick={() => subscribe()}
                        >
                            Subscribe Newsletter
                        </a>
                    </div>
                </form>
                {loginResponse === 'success' && <div className="w-form-done" style={{ display: 'block', marginBottom: 5 }}>
                    <div>That was easy, You now subscribed!</div>
                </div>
                }

                {loginResponse === 'error' && <div className="w-form-fail" style={{ display: 'block', marginBottom: 5 }}>
                    <div>Oops! Something went wrong while submitting the form.</div>
                </div>
                }
            </div>
        </div>
    </>
}