import moment from "moment";

import { productSlice } from "./productSlice";
const { actions } = productSlice;

export const setSelectedProduct = (product) => dispatch => {
    dispatch(actions.selectedProductOnList(product));
};

export const setMinimumAmountAndData = (data, orderExist) => dispatch => {
    let minDate = null;
    let minAmmount = null;
    let minDateId = null;

    if (data.length > 0) {
        minDate = moment(data[0].next_date).set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toDate();
        minAmmount = parseFloat(data[0].MinOrderValue);
        minDateId = parseFloat(data[0].id);
    }

    if (!orderExist) {
        dispatch(actions.setSelectedDeliveryDate(minDateId));
    }

    dispatch(actions.setMinimumAmountAndDate({
        minDate,
        minAmmount,
        minDateId
    }));
};

export const selectDeliveryDates = (id) => dispatch => {
    dispatch(actions.setSelectedDeliveryDate(id))
}

export const addItemOnCart = (item, count) => (dispatch, getState) => {
    let currentCart = new Array(...getState().products.cart);

    const productAlreadyExist = currentCart.find(f => f["id"] === item["id"]);

    if (productAlreadyExist) {
        currentCart = currentCart.filter(f => f["id"] !== item["id"]);
        currentCart.push({ ...item, guid: broofa(), count: parseInt(count) + parseInt(productAlreadyExist["count"]) });
    } else {
        currentCart.push({ ...item, guid: broofa(), count });
    }

    dispatch(actions.setCart(currentCart));
}

export const clearCartItems = () => (dispatch) => {
    localStorage.removeItem("k_cart");
    dispatch(actions.setCart([]));
}

export const setProductsFromLocal = (data) => (dispatch) => {
    dispatch(clearCartItems());

    let currentCart = [];
    data.forEach(item => {
        currentCart.push({ ...item, guid: broofa() });
    });

    dispatch(actions.setCart(currentCart));
}

export const removeCartItem = (item) => (dispatch, getState) => {
    let currentCart = new Array(...getState().products.cart);
    currentCart = currentCart.filter(f => f['guid'] !== item["guid"]);

    dispatch(actions.setCart(currentCart));
}

function broofa() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const testCartItems = (data) => (dispatch) => {
    dispatch(actions.testReducer(data));
}

export const setCoupon = (data) => (dispatch) => {
    dispatch(actions.setCoupon(data));
}
