import axios from "axios";

export async function fetchFamiliesAndRegions() {
    try {

        const result = await axios.get("/auth/families-regions");

        return result.data;
    } catch (error) {
        console.log("Err ", error);
        throw error;
    }
}

export async function mailChimpSubscribe({ region, email }) {
    try {

        const result = await axios.post("/mail-chimp-subscribe", {
            region, email
        });

        return result.data;
    } catch (error) {
        console.log("Err ", error);
        throw error;
    }
}

export async function keepInTouch({ name, phone }) {
    try {

        const result = await axios.post("/keep-in-touch", {
            name, phone
        });

        return result.data;
    } catch (error) {
        console.log("Err ", error);
        throw error;
    }
}

export async function contactUs({ email, name, freeText, contactUsType }) {
    try {

        const result = await axios.post("/contact-us", {
            email, name, freeText, contactUsType
        });

        return result.data;
    } catch (error) {
        console.log("Err ", error);
        throw error;
    }
}

export async function updateAccountDetails(data, isAddress = false) {
    try {
        const result = await axios.post("/account/update", { ...data, isAddress });

        return result.data;
    } catch (error) {
        console.log("Err ", error);
        throw error;
    }
}

export async function getAccount() {
    try {
        const result = await axios.get("/account");

        return result.data;
    } catch (error) {
        console.log("Err ", error);
        throw error;
    }
}

export async function getProducts(selectedCategory) {
    try {

        const result = await axios.get("/products?id=" + selectedCategory);

        return result.data;
    } catch (error) {
        console.log("Err ", error);
        throw error;
    }
}

export async function getCartProducts(filterByCart, orderIDs = false) {
    try {

        const result = await axios.post("/products", {
            filterByCart,
            orderIDs
        });

        return result.data;
    } catch (error) {
        console.log("Err ", error);
        throw error;
    }
}

export async function getCategories() {
    try {

        const result = await axios.get("/products/categories");

        return result.data;
    } catch (error) {
        console.log("Err ", error);
        throw error;
    }
}

export async function getMinimumDateAndAmount() {
    try {

        const result = await axios.get("/products/minimum-amount");

        return result.data;
    } catch (error) {
        console.log("Err ", error);
        throw error;
    }
}

export async function createOrder(cart, deliveryDateId, minDate, details, coupon, selectedDeliveryDates) {
    try {

        const result = await axios.post("/products/order", {
            cart,
            deliveryDateId,
            minDate,
            details,
            coupon,
            selectedDeliveryDates
        });

        return result.data;
    } catch (error) {
        console.log("Err ", error);
        throw error;
    }
}