import React from "react";
import { Link } from "react-router-dom";
import SubscribeFooter from "./FooterSubscribe";

class FooterGreen extends React.Component {
  render() {
    return (
      <footer className='footer footer-green'>
        <div className='footer-newsletter-top' />
        <div className='lgg-container'>
          <div className='footer-inner'>
            <div className='footer-upper'>
              <div className='footer-top-top'>
                <div className='footer-uleft'>
                  <h3 className='lgg-footer-about-text'>
                    Great! Weekly savings of up to 20% off compared to mall grocery stores
                  </h3>
                </div>
                <div className='footer-top-cta-wrap'>
                  <Link
                    to='/sourcing'
                    className='lgg-cta-big lgg-cta-big-primary secondary-btn lgg-cta-big-primary--outline'>
                    our sourcing
                  </Link>
                  <Link
                    to='/register'
                    className='lgg-cta-big lgg-cta-big-primary lgg-cta-big-primary--blue mr-0 lgg-cta-big-primary--white'>
                    Get Started
                  </Link>
                </div>
              </div>
              <div className='footer-uright'>
                <div className='footer-left'>
                  <a href='#' className='headerlogo is--footer w-inline-block'>
                    <div className='headerlogo__text'>Kiondo Market</div>
                  </a>
                  <div className='footer-content-grid'>
                    <div className='footerleft-content'>
                      <div className='footer-menu-title mb-20'>Pickup/Retail Center</div>
                      <div className='footertext'>
                        Kihingo, Kirawa Rd, Kitisuru West
                        <br />
                      </div>
                      <div className='footertext'>
                        sales@kiondo.market
                        <br />
                      </div>
                      <div className='footertext'>
                        +254 780514530
                        <br />
                      </div>
                    </div>
                    <div className='footerleft-content is---mobileright'>
                      <div className='footer-menu-title mb-20'>
                        Follow us on
                      </div>
                      <div className='flex-h mob-alignright'>
                        <a
                          href='https://web.facebook.com/KiondoMarket'
                          className='footer-socialicon w-inline-block'>
                          <img
                            src='images/facebook.svg'
                            loading='lazy'
                            alt
                            className='social-ing'
                          />
                        </a>
                        <a
                          href='https://www.instagram.com/kiondomarket'
                          className='footer-socialicon w-inline-block'>
                          <img
                            src='images/instagram.svg'
                            loading='lazy'
                            alt
                            className='social-ing'
                          />
                        </a>
						<a
                          href='https://twitter.com/KiondoMarket'
                          className='footer-socialicon w-inline-block'>
                          <img
                            src='images/twitter.svg'
                            loading='lazy'
                            alt
                            className='social-ing'
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <SubscribeFooter type="green" /> 
              </div>
            </div>
          </div>
        </div>
        <div className='footer-bottom'>
          <div className='lgg-container lgg-container-footer'>
            <div className='footer-copy-text'>
              Copyright © Kiondo Market Ltd. All rights reserved
            </div>
            <div className='flex-h is-priv'>
              <a href='https://www.kiondo.market/termsprivacy/terms.html' className='footer__copylink'>
                Terms of Service
              </a>
              <a href='https://www.kiondo.market/termsprivacy/privacy.html' className='footer__copylink'>
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
        <img
          src='images/plant.svg'
          loading='lazy'
          alt
          className='footer--plant'
        />
      </footer>
    );
  }
}

export default FooterGreen;
