import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import CartSidebar from "./CartSidebar";

function MobileHeaderLogin() {
  const [openCart, setOpenCart] = useState(false);
  const { cart } = useSelector(
    (state) => ({
      cart: state.products.cart,
    }),
    shallowEqual
  );
  const openMenu = () => {
    document
      .querySelector(".mobile__navigation-btn")
      ?.classList.toggle("is--open");
    // add overlow hidden to body
    document.querySelector("body")?.classList.toggle("overflow-hidden");
    document
      .querySelector("body")
      ?.classList.toggle("body-mobile-menu-active");
    // mobile-header-active to the elements parent's parent
    document
      .querySelector(".mobile__navigation-btn")
      .parentElement.parentElement?.classList.toggle(
        "mobile-header-active"
      );

    // add mobile__menushow .mobile__menu
    document
      .querySelector(".mobile__menu")
      ?.classList.toggle("mobile__menushow");
  }

  const handleCLick = () => {
    setOpenCart(true)
  }
  return (
    <>

      <div className='mobile__header'>
        <Link to='/' className='headerlogo w-inline-block'>
          <div className='headerlogo__text'>Kiondo Market</div>
        </Link>
        <button
          // className="headercta is--filled is--viewcart w-button"
          onClick={handleCLick}
        >
          {cart.length > 0 && (
            <div
              style={{
                padding: 4,
                marginRight: 4,
                backgroundColor: "white",
                color: "black",
              }}
            >
              {cart.length}
            </div>
          )}
          View Cart
        </button>
        <div className='w-embed'>
          <button
            onClick={openMenu}
            type='button'
            aria-label='Open and close navigation menu'
            aria-haspopup='true'
            data-test='open'
            className='mobile__navigation-btn' >
            <span className='mobile__menu-line is--top' />
            <span className='mobile__menu-line is--bottom' />
          </button>
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n.mobile__navigation-btn{\n    width: 34px;\n    height: 24px;\n    position: relative;\n    cursor: pointer;\n        border: none;\n    margin: 0;\n    padding: 0;\n    text-align: inherit;\n    background: transparent;\n    color: inherit;\n    font: inherit;\n    line-height: normal;\n    transform:scale(0.9);\n    }\n .mobile__menu-line{\n     backface-visibility: hidden;\n    width: 100%;\n    height: 4px;\n    position: absolute;\n    left: 0px;\n    top: 10px;\n    transform-origin: center center;\n    transition: transform 0.3s ease 0s, background 0.3s ease 0s;\n    background-image: url(https://uploads-ssl.webflow.com/609ba4da89f30169bedb2624/60c542a39af862c712eea909_mobile__nav-line.svg);\n    }\n       .mobile__menu-line.is--top{  \n    transform: translateY(-4.5px);\n    }\n .mobile__menu-line.is--bottom{  \n    transform: translateY(4.5px);\n    }\n.mobile__navigation-btn.is--open .mobile__menu-line.is--top{\ntransform: rotate(45deg);\n}\n.mobile__navigation-btn.is--open .mobile__menu-line.is--bottom{   \ntransform: rotate(-45deg);\n}\n"
            }}
          />
        </div>

      </div>
      {openCart && (
        <CartSidebar setOpenCart={setOpenCart} openCart={openCart} />
      )}
    </>
  );
}


export default MobileHeaderLogin;
