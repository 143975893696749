export function getFormattedPrice(params) {
    return new Intl.NumberFormat('KEN', { style: 'currency', currency: 'KES', minimumFractionDigits: 0, minimumFractionDigits: 2, }).format(parseFloat(params).toFixed(2))
}

export function calculateOrderPrice(cart, delivery, coupon) {
    let response = {
        total: 0,
        subTotal: 0,
        deliveryPrice: delivery?.Amount || 0,
        totalDiscount: 0,
        coupon: coupon ? parseFloat(coupon.amount) : 0
    }

    try {
        if (cart) {
            let price = 0;
            let discount = 0;
            let priceWithoutDiscoutn = 0;

            cart.forEach((e) => {
                let ePrice = e["price"];

                if (e["product_discounts"]) {
                    ePrice = ePrice - ((e.price / 100) * e["product_discounts"].discount_percentage)
                    discount += (e.price * e["count"]) / 100 * e["product_discounts"].discount_percentage;
                }

                priceWithoutDiscoutn += e.price * e.count;
                price += parseFloat(e["count"] * ePrice);
            });

            response.totalDiscount = discount;
            response.subTotal = priceWithoutDiscoutn;
            response.total = response.subTotal + response.deliveryPrice - response.totalDiscount - response.coupon;
        }

    } catch (error) {

    }

    return response;
}