import axios from "axios";

export async function getUserBox() {
    try {
        const result = await axios.get(`/orders/user`);

        return result.data;
    } catch (error) {
        console.log("Err ", error);
        throw error;
    }
}

export async function getUserOrder(id, customer = false) {
    try {

        const result = await axios.post(`/orders/preview`, {
            order: id,
            customer
        });

        return result.data;
    } catch (error) {
        console.log("Err ", error);
        throw error;
    }
}

export async function updateOrder(cart, orderDelivery, coupon) {
    try {
        const result = await axios.put(`/orders`, { cart, orderDelivery, coupon });

        return result.data;
    } catch (error) {
        console.log("Err ", error);
        throw error;
    }
}

export async function validateCouponCode(coupon) {
    try {
        const result = await axios.post(`/orders/validate-coupon`, { coupon });

        return result.data;
    } catch (error) {
        console.log("Err ", error);
        throw error;
    }
}

export async function fetchDeliveryDates() {
    try {
        const result = await axios.get(`/orders/available-dates`);

        return result.data;
    } catch (error) {
        console.log("Err ", error);
        throw error;
    }
}

export async function setDeliveryDates(data) {
    try {
        const result = await axios.post(`/orders/available-dates`, data);

        return result.data;
    } catch (error) {
        console.log("Err ", error);
        throw error;
    }
}

export async function getOrderPrice() {
    try {
        const result = await axios.get(`/orders/price-info`);

        return result.data;
    } catch (error) {
        console.log("Err ", error);
        throw error;
    }
}

export async function getPreviousDeliveries() {
    try {
        const result = await axios.get(`/orders/previous-deliveries`);

        return result.data;
    } catch (error) {
        console.log("Err ", error);
        throw error;
    }
}