import React from "react";

class Loader extends React.Component {
  render() {
    return (
      // add new loader
      <div>
      <div className="page_loader">
        <div className="page_loader-icon">
        <div
            style={{
              
              border: "8px solid #f3f3f3",
              borderTop: "8px solid #026D60",
              borderRadius: "50%",
              width: "80px",
              height: "80px",
              animation: "spin 2s linear infinite",
            }}
          ></div>
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n\t\t.page_loader {\n\t\t\tposition: fixed;\n\t\t\theight: 100%;\n\t\t\twidth: 100%;\n\t\t\tz-index: 999999;\n\t\t\tbackground-color: #f6f0e8;\n\t\t\tdisplay: flex;\n\t\t\t-webkit-box-orient: vertical;\n\t\t\t-webkit-box-direction: normal;\n\t\t\t-webkit-flex-direction: column;\n\t\t\t-ms-flex-direction: column;\n\t\t\tflex-direction: column;\n\t\t\t-webkit-box-pack: center;\n\t\t\t-webkit-justify-content: center;\n\t\t\t-ms-flex-pack: center;\n\t\t\tjustify-content: center;\n\t\t\t-webkit-box-align: center;\n\t\t\t-webkit-align-items: center;\n\t\t\t-ms-flex-align: center;\n\t\t\talign-items: center;\n\t\t}\n\t",
        }}
      />
    </div>
      // <div>
      //   <div className='page_loader'>
      //     <div className='page_loader-icon'>
      //       <lottie-player
      //         src='/loader.json'
      //         background='transparent'
      //         speed={1}
      //         style={{
      //           width: "300px",
      //           height: "300px"
      //         }}
      //         loop
      //         // controls
      //         autoPlay
      //       />
      //     </div>
      //   </div>
      //   <style
      //     dangerouslySetInnerHTML={{
      //       __html:
      //         "\n\t\t.page_loader {\n\t\t\tposition: fixed;\n\t\t\theight: 100%;\n\t\t\twidth: 100%;\n\t\t\tz-index: 999999;\n\t\t\tbackground-color: #f6f0e8;\n\t\t\tdisplay: flex;\n\t\t\t-webkit-box-orient: vertical;\n\t\t\t-webkit-box-direction: normal;\n\t\t\t-webkit-flex-direction: column;\n\t\t\t-ms-flex-direction: column;\n\t\t\tflex-direction: column;\n\t\t\t-webkit-box-pack: center;\n\t\t\t-webkit-justify-content: center;\n\t\t\t-ms-flex-pack: center;\n\t\t\tjustify-content: center;\n\t\t\t-webkit-box-align: center;\n\t\t\t-webkit-align-items: center;\n\t\t\t-ms-flex-align: center;\n\t\t\talign-items: center;\n\t\t}\n\t"
      //     }}
      //   />
      // </div>
    );
  }
}

export default Loader;
