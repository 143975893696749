import React from "react";

const UpcomingOrder = React.lazy(async () => {
  // await new Promise((resolve) => setTimeout(resolve, 3000));
  return import("./components/UpcomingOrder");
});
const MyOrderPage = React.lazy(async () => {
  // await new Promise((resolve) => setTimeout(resolve, 3000));
  return import("./components/MyOrderPage");
});
const UpcomingViewAll = React.lazy(async () => {
  // await new Promise((resolve) => setTimeout(resolve, 3000));
  return import("./components/UpcomingViewAll");
});
const ViewAllOrder = React.lazy(async () => {
  // await new Promise((resolve) => setTimeout(resolve, 3000));
  return import("./components/ViewAllOrder");
});

const CategoryPage = React.lazy(async () => {
  //  await new Promise((resolve) => setTimeout(resolve, 3000));
  return import("./components/CategoryPage");
});

const AccountPage = React.lazy(async () => {
  // await new Promise((resolve) => setTimeout(resolve, 3000));
  return import("./components/AccountPage");
});

const BuildBox = React.lazy(async () => {
  //await new Promise((resolve) => setTimeout(resolve, 3000));
  return import("./components/BuildBox");
});

const ShippingPage = React.lazy(async () => {
  // await new Promise((resolve) => setTimeout(resolve, 3000));
  return import("./components/ShippingPage");
});
const ReferralsPage = React.lazy(async () => {
  // await new Promise((resolve) => setTimeout(resolve, 3000));
  return import("./components/ReferralsPage");
});

const dashRoutes = [
  {
    path: "/dashboard/upcomingbox",
    exact: true,
    component: UpcomingOrder,
    name: "Upcoming Box",
  },
  {
    path: "/dashboard/myorders",
    exact: true,
    component: MyOrderPage,
    name: "my orders",
  },
  {
    path: "/dashboard/upcomingbox/viewAll",
    exact: true,
    component: UpcomingViewAll,
    name: "Upcoming ViewAll",
  },
  {
    path: "/dashboard/myorders/viewall/:id",
    exact: true,
    component: ViewAllOrder,
    name: "Orders ViewAll",
  },
  {
    path: "/dashboard/shop",
    exact: true,
    component: CategoryPage,
    name: "shop",
  },
  {
    path: "/dashboard/myaccount",
    exact: true,
    component: AccountPage,
    name: "My Account",
  },
  {
    path: "/dashboard/build-a-box",
    exact: true,
    component: BuildBox,
    name: "Build a Box",
  },
  {
    path: "/dashboard/delivery",
    exact: true,
    component: ShippingPage,
    name: "Delivery",
  },
  {
    path: "/dashboard/referrals",
    exact: true,
    component: ReferralsPage,
    name: "Delivery",
  },
];

export default dashRoutes;
