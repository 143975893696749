import React from "react";
import { Link } from "react-router-dom";
class CtaStripYellow extends React.Component {
  render() {
    return (
      <div className='ctastrip is--yellow'>
        <div className='container'>
          <div className='ctastrip__inner'>
            <h3>Still have a Question?</h3>
            <div className='header__ctawrap is-cta'>
              <Link
                to='/faq'
                className='headercta is--filled strip-btn is--white w-button'>
                Read FAQs
              </Link>
              <a
                href='/contact'
                className='headercta is--outline strip-btn is--blackoutlne w-button'>
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CtaStripYellow;
