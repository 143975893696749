import React from 'react'
import help from "../assets/icons/help.svg";
const NeedHelp = () => {
  return (
    <>
    <h1 className="headingLG marginBottom16">Need Help?</h1>
      <div className="whiteBackground p32 marginBottom48 planStracture">
        <img alt="help" src={help} />
        <div className="flexColumn">
          <h1 className="headingMD2">Call us : 0721514540</h1>
         
         
        </div>
      </div>
    </>
  )
}

export default NeedHelp