import React from "react";
import { Link } from "react-router-dom";

class MobileHeader extends React.Component {
  openMenu() {
    document
						.querySelector(".mobile__navigation-btn")
						?.classList.toggle("is--open");
					// add overlow hidden to body
					document.querySelector("body")?.classList.toggle("overflow-hidden");
					document
						.querySelector("body")
						?.classList.toggle("body-mobile-menu-active");
					// mobile-header-active to the elements parent's parent
					document
						.querySelector(".mobile__navigation-btn")
						.parentElement.parentElement?.classList.toggle(
							"mobile-header-active"
						);

					// add mobile__menushow .mobile__menu
					document
						.querySelector(".mobile__menu")
						?.classList.toggle("mobile__menushow");
  }

  render() {
    return (
      <div className='mobile__header'>
        <Link to='/' className='headerlogo w-inline-block'>
          <div className='headerlogo__text'>Kiondo Market</div>
        </Link>
        <div className='w-embed'>
          <button
            onClick={this.openMenu}
            type='button'
            aria-label='Open and close navigation menu'
            aria-haspopup='true'
            data-test='open'
            className='mobile__navigation-btn'>
            <span className='mobile__menu-line is--top' />
            <span className='mobile__menu-line is--bottom' />
          </button>
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n.mobile__navigation-btn{\n    width: 34px;\n    height: 24px;\n    position: relative;\n    cursor: pointer;\n        border: none;\n    margin: 0;\n    padding: 0;\n    text-align: inherit;\n    background: transparent;\n    color: inherit;\n    font: inherit;\n    line-height: normal;\n    transform:scale(0.9);\n    }\n .mobile__menu-line{\n     backface-visibility: hidden;\n    width: 100%;\n    height: 4px;\n    position: absolute;\n    left: 0px;\n    top: 10px;\n    transform-origin: center center;\n    transition: transform 0.3s ease 0s, background 0.3s ease 0s;\n    background-image: url(https://uploads-ssl.webflow.com/609ba4da89f30169bedb2624/60c542a39af862c712eea909_mobile__nav-line.svg);\n    }\n       .mobile__menu-line.is--top{  \n    transform: translateY(-4.5px);\n    }\n .mobile__menu-line.is--bottom{  \n    transform: translateY(4.5px);\n    }\n.mobile__navigation-btn.is--open .mobile__menu-line.is--top{\ntransform: rotate(45deg);\n}\n.mobile__navigation-btn.is--open .mobile__menu-line.is--bottom{   \ntransform: rotate(-45deg);\n}\n"
            }}
          />
        </div>
      </div>
    );
  }
}

export default MobileHeader;
