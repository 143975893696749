import React, { useEffect, useState } from "react";
import moment from "moment";
import { pauseSubscription } from "../api/customers";
import $ from "jquery"
import { fetchDeliveryDates, getUserBox } from "../api/orders";
import { setUserOrder } from "../redux/redux/orders/orderActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { selectDeliveryDates } from "../redux/redux/products/productActions";

const modalId = "modal-item-select-delivery-date";

function SelectDeliveryDate({ data, showAddToOrder = true }) {

	const dispatch = useDispatch();

	const [dates, setDates] = useState([]);
	const [selectedDate, setPauseDate] = useState(moment().add(2, 'days').format("YYYY-MM-DD"));

	const { minDateId, selectedDeliveryDates } = useSelector(
		(state) => ({
			minDateId: state.products.minDateId,
			selectedDeliveryDates: state.products.selectedDeliveryDates,
		}),
		shallowEqual
	);

	useEffect(() => {
		onLoad();
	}, []);

	async function onLoad() {
		const deliveryDates = await fetchDeliveryDates();
		setDates(deliveryDates.data);
	}

	async function pauseSubscr() {
		$(".modal-container").removeClass("modal-container-open");
		$("body").removeClass("modal-open");
		$(".modal-content-wrap").css("display", "none");
		$(".modal-container").css("display", "none");
	}

	function selectDates(id) {
		dispatch(selectDeliveryDates(id));
	}

	return (
		<div
			id={modalId}
			className="modal-content-wrap modal-content-wrap-payment"
		>
			<div className="modal-appoint-righr is100">
				<div className="modal-title-wrap ml-0">
					<div className="modal-title">Preferred delivery day</div>
					<div className="fh-100-2 mt20" />
				</div>

				<div className="moda-content-main">
					<>
						<div className="columnFlex">
							<p className="paraMD" style={{ color: "#A84726" }} >
								Space is limited for each day. If you switch, your current slot
								may become unavailable.
							</p>
							{
								dates.map(m => {
									let date = "";

									const dayINeed = m.week_day + 1;
									const today = moment().isoWeekday();

									if (today <= dayINeed) {
										date = moment().isoWeekday(dayINeed).format("LL");
									} else {
										date = moment().add(1, 'weeks').isoWeekday(dayINeed).format("LL");
									}

									return (
										<div>
											<label>
												<p>
													<input type="checkbox" disabled={m.is_deleted == true} checked={selectedDeliveryDates.find(f => f == m.id)} onChange={val => selectDates(m.id, val.target.value)} />
													<span className="marginLeft12">{m.day} {m.is_deleted == false && <>({date})</>} {m.is_deleted == true && <span className="notAvailable">(Not Available)</span>}</span>
												</p>
											</label>
										</div>
									)
								})
							}
							<button
								className="yBtn marginBottom16"
								onClick={pauseSubscr}>
								Save
							</button>
						</div>
					</>
				</div>
				<a href="#" id={modalId} className="close-modal w-inline-block">
					<img src="/images/times.svg" loading="lazy" alt />
				</a>
			</div>
		</div>
	);

}

export default SelectDeliveryDate;
