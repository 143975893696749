import axios from "axios";

export async function pauseSubscription(pause_subscription) {
    try {
        const result = await axios.post(`/customer/pause`, { pause_subscription });

        return result.data;
    } catch (error) {
        console.log("Err ", error);
        throw error;
    }
}

export async function sendCustomerFeedback(text) {
    try {
        const result = await axios.post(`/customer/feedback`, { text });

        return result.data;
    } catch (error) {
        console.log("Err ", error);
        throw error;
    }
}