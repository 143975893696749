import React, { useEffect } from "react";
import ModalItem from "../ModalItem";
import ModalPayment from "../ModalPayment";
import ModalOtp from "../ModalOtp";
import "./index.css";
import $ from "jquery";
import PauseSubscription from "../PauseSubscription";
import UnPauseSubscription from "../UnPauseSubscription";
import SelectDeliveryDate from "../SelectDeliveryDate";
import { SendFeedback } from "../SendFeedback";

const ShopModal = () => {
  useEffect(() => {
    $("a[data-trigger=modal]").on("click", function () {
      $(".modal-content-wrap").css("display", "none");
      const modalId = $(this).data("target");
      $(".modal-container").css("display", "flex");
      $("#" + modalId).css("display", "flex");
      $(".modal-container").addClass("modal-container-open");
      $("body").addClass("modal-open");
    });

    $("a.close-modal").on("click", function () {
      $(".modal-container").removeClass("modal-container-open");
      $("body").removeClass("modal-open");
      $(".modal-content-wrap").css("display", "none");
      $(".modal-container").css("display", "none");
    });
  }, []);

  return (
    <div className='modal-container'>
      <div className='modal-container-inner'>
        <ModalItem></ModalItem>
        <ModalPayment></ModalPayment>
        <ModalOtp></ModalOtp>
        <PauseSubscription />
        <UnPauseSubscription />
        <SelectDeliveryDate />
        <SendFeedback />
      </div>
    </div>
  );
};

export default ShopModal;
