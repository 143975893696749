import { combineReducers } from "redux";
import { authSlice } from "./redux/login/authSlice";
import { ordersSlice } from "./redux/orders/ordersSlice";
import { productSlice } from "./redux/products/productSlice";

export const rootReducer = combineReducers({
	auth: authSlice.reducer,
	products: productSlice.reducer,
	userOrder: ordersSlice.reducer
});
