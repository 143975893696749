import React, { useEffect, useState, useReducer } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchFamiliesAndRegions } from "../api";
import { validateCouponCode } from "../api/orders";
import { setCoupon } from "../redux/redux/products/productActions";
import { getFormattedPrice } from "../utils";


const CartSubtotal = ({ totalPrice, isEdit, deliveryPrice, price }) => {

    const reduxDispatch = useDispatch();
    const [couponValue, setCouponValue] = useState(null);
    const [couponResponseError, setCouponResponseError] = useState(null);

    const { coupon } = useSelector(
        (state) => ({
            coupon: state.products.coupon,
        }),
        shallowEqual
    );

    useEffect(() => {
        if (coupon && coupon.coupon_code) {
            setCouponValue(coupon.coupon_code);
        }
    }, [coupon]);

    async function validateCoupon() {
        try {
            setCouponResponseError(null);
            const result = await validateCouponCode(couponValue);

            if (result.data) {
                reduxDispatch(setCoupon(result.data));
            } else {
                setCouponResponseError("Coupon is invalid or expired!");
            }

            return true;
        } catch (error) {
            throw error;
        }
    }

    return (
        <>
            <div
                style={{ display: "flex", flexDirection: "column", padding: "20px", backgroundColor: "#FFFFFF", marginTop: "4px" }}
            >
                <div>
                    <label className="cartSubLabel">Coupon or Gift Card (Optional)</label>

                    <input
                        className="cartSubLabel"
                        style={{
                            width: "300px",
                            padding: "8px",
                            border: "1px solid #F0F0F0",
                            borderRadius: "4px",
                        }}
                        value={couponValue}
                        onChange={(val) => setCouponValue(val.target.value.toUpperCase())}
                        placeholder="Code or gift card"
                        disabled={coupon}
                    />
                    <br></br>
                    {/* {couponValue && couponValue.length == 6 && <a href="#">Apply code</a>} */}
                    {couponValue && !coupon && <button
                        className="emptyCartBtn"
                        style={{
                            width: "300px",
                            backgroundColor: "#026d60",
                            color: "#FFFFFF",
                            marginTop: "4px",
                            marginBottom: "12px",
                        }}
                        onClick={validateCoupon}
                    >
                        Apply coupon
                    </button>}
                    {couponResponseError && <span style={{ color: 'red' }}>{couponResponseError}</span>}
                    {coupon && !coupon.couponUsed && <a href="#" onClick={() => reduxDispatch(setCoupon(null))}>Remove coupon code?</a>}
                </div>
                <div style={{ marginTop: "32px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <p className="cartSubLabel">Subtotal</p>
                        <p className="cartSubLabel">{`${getFormattedPrice(price.subTotal)}`}</p>
                    </div>
                    {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p  className="cartSubLabel">Est. Tax</p>
            <p  className="cartSubLabel">$41.96</p>
          </div> */}
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <p className="cartSubLabel">Delivery/Packaging</p>
                        <p className="cartSubLabel">{getFormattedPrice(price.deliveryPrice)}</p>
                    </div>
                    {price.coupon > 0 && <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <p className="cartSubLabel" style={{ color: 'red' }}>Coupon</p>
                        <p className="cartSubLabel" style={{ color: 'red' }}>- {getFormattedPrice(price.coupon)}</p>
                    </div>}
                    {price.totalDiscount > 0 && <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <p className="cartSubLabel" style={{ color: 'red' }}>Total Discount</p>
                        <p className="cartSubLabel" style={{ color: 'red' }}>- {getFormattedPrice(price.totalDiscount)}</p>
                    </div>}
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <p className="cartSubLabel">Total</p>
                        <p className="cartSubLabel">{getFormattedPrice(price.total)}</p>
                    </div>
                </div>
                {/* <p  className="cartSubLabel">You’re saving $33.99</p> */}
            </div>
        </>
    );
};

export default CartSubtotal;
