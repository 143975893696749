import React from "react";

const CartBottom = () => {
  return (
    <>
      <div style={{ backgroundColor: "#FFEFD7", padding: "20px" }}>
        <h1 className="cartBottomFAQ">FAQs</h1>

        <h1 className="cartBottomCheckOut">How do I checkout?</h1>
        <p className="cartBottomPara">
          Once you reach the required Order amount the cart show Finish Up to complete your order. 
		  We will send you a WhatsApp interaction to confirm subsequent weekly orders and payment details
        </p>
        <h1 className="cartBottomCheckOut">What is the order minimum?</h1>
        <p className="cartBottomPara">
          Kiondo Market allows a minimum order value of KES:1000 This ensures
          we’re able to serve you efficiently and deliver your order economically.
        </p>
        <h1 className="cartBottomCheckOut">Shopping Window</h1>
        <p className="cartBottomPara">
          We have selected delivery days within a week. Our order cut off time
          is <span style={{fontWeight:"600"}}>24hrs to the delivery day</span> . Orders outside the shopping window will
          be delivered on next available delivery schedule.
        </p>
      </div>
    </>
  );
};

export default CartBottom;
