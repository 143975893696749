import React from "react";
import { Link } from "react-router-dom";

class MobileMenuLogin extends React.Component {
  render() {
    return (
      <div className="mobile__menu">
        <Link to="/" className="mobilemenu_linkblock w-inline-block">
          <div className="mobile__menulink">Home</div>
        </Link>
        <Link to="/ourstory" className="mobilemenu_linkblock w-inline-block">
          <div className="mobile__menulink">Our Story</div>
        </Link>
        <Link to="/sourcing" className="mobilemenu_linkblock w-inline-block">
          <div className="mobile__menulink">Our Sourcing</div>
        </Link>
        <Link to="/contact" className="mobilemenu_linkblock w-inline-block">
          <div className="mobile__menulink">Contact Us</div>
        </Link>
        <Link to="/faq" className="mobilemenu_linkblock w-inline-block">
          <div className="mobile__menulink">FAQs</div>
        </Link>
		 <Link to="/login" className="mobilemenu_linkblock w-inline-block">
          <div className="mobile__menulink">Log In</div>
        </Link>
        <Link to="/register" className="button__large is--coloryellow is-100 w-button">
          Get Started
        </Link>
      </div>
    );
  }
}

export default MobileMenuLogin;
