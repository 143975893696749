import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import plus from "../assets/icons/plus.svg";
import dash from "../assets/icons/dash.svg";
import ship from "../assets/icons/ship.svg";
import trash from "../assets/icons/trash.svg";
import moment from "moment";
import { toast } from "react-toastify";

import {
    clearCartItems,
    removeCartItem,
} from "../redux/redux/products/productActions";
import { useHistory } from "react-router";
import { calculateOrderPrice, getFormattedPrice } from "../utils";
import { updateOrder } from "../api/orders";
import { fetchFamiliesAndRegions } from "../api";
import EmptyCart from "../assets/EmptyCart";
import CartBottom from "./CartBottom";
import { productSlice } from "../redux/redux/products/productSlice";
import CartSubtotal from "./CartSubtotal";
import { fetchUserOrderAndPrice } from "../redux/redux/orders/orderActions";
const { actions } = productSlice;

function CartSidebar({ setOpenCart, openCart }) {
    const dispatch = useDispatch();
    const history = useHistory();

    const [updateBtnDisabled, setDisabledUpdateBtn] = useState(false);

    const { minDate, minAmmount, cart, userOrder, coupon, editMode, deliveryPrice } = useSelector(
        (state) => ({
            minDate: state.products.minDate,
            minAmmount: state.products.minAmmount,
            cart: state.products.cart,
            userOrder: state.userOrder.userOrder,
            coupon: state.products.coupon,
            editMode: state.userOrder.editMode,
            deliveryPrice: state.userOrder.deliveryPrice
        }),
        shallowEqual
    );

    useEffect(() => {
        if (userOrder) {
            if (userOrder?.details?.status_id >= 4) {
                setDisabledUpdateBtn(true);
            }
        }
    }, [userOrder]);

    function removeItem(item) {
        dispatch(removeCartItem(item));
    }

    async function create() {
        try {
            if (true) {
                history.push("/dashboard/delivery");
            }
        } catch (error) {
            throw error;
        }
    }

    async function update() {
        try {
            setDisabledUpdateBtn(true);

            const result = await updateOrder(cart, userOrder.details, coupon);

            if (result.success === true) {
                dispatch(fetchUserOrderAndPrice());

                toast.success("Order updated successfully!");

                setTimeout(() => {
                    dispatch(clearCartItems());
                    window.location.reload(false);
                }, 1000);
            }

            setDisabledUpdateBtn(false)
            return;
        } catch (error) {
            setDisabledUpdateBtn(false)
            throw error;
        }
    }

    function addItem(data) {
        dispatch(actions.cartIncrement(data["id"]));
    }
    function minusItem(data) {
        dispatch(actions.cartDecrement(data["id"]));
    }

    const pricer = calculateOrderPrice(cart, deliveryPrice, coupon);

    const nextDate = userOrder.details ? userOrder.details.delivery_date : minDate;

    return (
        <div className="container containerExtra">
            <div className="cart--container-cart ">
                <div
                    className="shop__content-tabswrap forMobile"

                    style={{ backgroundColor: "#FFF" }}
                >
                    <div
                        className="shop__sectiontitle-big "
                        style={{
                            marginTop: "22px",
                            paddingLeft: "16px",
                        }}
                    >
                        <p className="cartTitle">{editMode ? "Edit Cart" : "Cart"}</p>
                        {
                            editMode && userOrder.details && userOrder.details.status_id > 1 && <p style={{ fontSize: 12 }}>Changes will be applied on next delivery date!</p>
                        }
                    </div>
                    {pricer.total > minAmmount ? (
                        <p className="topST"> {`${getFormattedPrice(pricer.total)} Total`} </p>
                    ) : null}
                    <a className="crossIcon" onClick={() => setOpenCart(false)}>
                        <img
                            className="crossIcon"
                            src="/images/times.svg"
                            width={20}
                            height={20}
                            loading="lazy"
                            alt
                        />
                    </a>
                </div>

                {!cart.length && (
                    <>
                        <EmptyCart />
                        <div
                            style={{ backgroundColor: "#F5F5F5", padding: "8px 0px 8px 0px" }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    backgroundColor: "#F0DFD2",
                                    padding: "12px",
                                }}
                            >
                                <img style={{ paddingLeft: "22px" }} src={ship} />

                                <p
                                    style={{ marginLeft: "14px" }}
                                    className="emptyCartDeliveryPara"
                                >
                                    {` Delivery on ${moment(nextDate).format("DD MMMM")} `}
                                </p>
                            </div>
                        </div>
                        <div style={{ padding: "20px", backgroundColor: "#FFFFFF" }}>
                            <button className="emptyCartBtn" onClick={() => {
                                setOpenCart(false)
                                history.push("/dashboard/shop")
                            }}>Start Shopping</button>
                        </div>
                    </>
                )}

                <a
                    className="close-modal w-inline-block is--viewcart"
                    onClick={() => {
                        setOpenCart(false);
                    }}
                >
                    <img
                        src="/images/times.svg"
                        width={20}
                        height={20}
                        loading="lazy"
                        alt
                    />
                </a>
                {cart.length ? (
                    <div
                        className="cartitem--container-ccart cartProduct "
                        style={{
                            marginTop: "0px",

                            padding: "0px",
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: "#D6ECE9",
                                padding: "16px 42px",
                                width: "100%",
                                margin: "4px 0px",
                            }}
                        >
                            <p className="cartReadyToShip" style={{ marginBottom: "0px" }}>
                                <span style={{ color: "#026459" }}> Ready to Deliver</span> {`on  ${moment(nextDate).format("DD MMMM")}`}
                            </p>
                        </div>

                        <div style={{ margin: "4px 22px 20px 22px" }}>
                            <div
                                style={{
                                    backgroundColor: "#FFFFFF",
                                    padding: "16px 22px",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <h1 className="cartGroc">{`Groceries (${cart.length})`}</h1>
                                {pricer.total < minAmmount ? (
                                    <div
                                        style={{
                                            backgroundColor: "#CF1F1F",
                                            borderRadius: "24px",
                                            width: "120px",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <p className="Incomplete">Incomplete</p>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            backgroundColor: "#D6ECE9",
                                            borderRadius: "24px",
                                            width: "120px",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <p className="ReadyTo">Ready to Deliver</p>
                                    </div>
                                )}
                            </div>

                            {cart.map((m, i) => {
                                let price = m["price"];

                                if (m["product_discounts"]) {
                                    price = price - ((m.price / 100) * m["product_discounts"].discount_percentage)
                                }

                                return (
                                    <>
                                        <div
                                            style={{
                                                display: "grid",
                                                gridTemplateColumns: " 1fr 4fr 1fr",
                                                columnGap: "10px",
                                                padding: "16px",
                                                // marginBottom: "16px",
                                                borderBottom: "1px solid #F0F0F0",
                                                // margin: "36px 22px 20px 22px",
                                                backgroundColor: "#FFFFFF",
                                            }}
                                            key={i}
                                        >
                                            <img
                                                src={`${m["image"]}`}
                                                loading="lazy"
                                                sizes="(max-width: 479px) 50px, 60px"
                                                srcSet={`${m["image"]} 500w, ${m["image"]} 800w, ${m["image"]} 1080w, ${m["image"]} 3300w`}
                                                alt
                                                className="product-card-image is--cart"
                                            />
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <p className="productDetail">{m["name"]}</p>
                                                <p className="productDetail">{m["short_description"]}</p>
                                                <div style={{ display: "flex" }}>
                                                    {m.count >= 2 ? (
                                                        <img
                                                            alt=""

                                                            src={dash}
                                                            onClick={() => minusItem(m)}
                                                            style={{ cursor: "pointer", width: "fit-content" }}
                                                        />
                                                    ) : (
                                                        <img
                                                            src={trash}
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => removeItem(m)}
                                                        />
                                                    )}
                                                    <p className="cartCount" style={{ margin: "0px 22px" }}>{m["count"]}</p>
                                                    <img
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => addItem(m)}
                                                        src={plus}
                                                    />
                                                </div>
                                            </div>
                                            <div className="product-card-price is--cart cartProductPrice">
                                                <p className="productDetail" style={{ margin: "0px" }}> {getFormattedPrice(price * m["count"])}{" "}</p>

                                                <span style={{ fontSize: "small" }} className="cartProductPrice">
                                                    {" "}
                                                    (x{m["count"]} * {getFormattedPrice(price)})
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                ) : null}

                {cart.length ? (
                    pricer.total < minAmmount ? (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "20px",
                                    backgroundColor: "#FFFFFF",
                                    marginTop: "4px",
                                }}
                            >
                                <div>
                                    {!editMode && pricer.total > minAmmount &&
                                        <>
                                            <label className="cartSubLabel">
                                                Coupon or Gift card (Optional)
                                            </label>

                                            <input
                                                className="cartSubLabel"
                                                style={{
                                                    width: "300px",
                                                    padding: "8px",
                                                    border: "1px solid #F0F0F0",
                                                    borderRadius: "4px",
                                                }}
                                                placeholder="Code or gift card"
                                            />
                                        </>
                                    }
                                </div>
                                <div style={{ marginTop: "32px" }}>
                                    <div
                                        style={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                        <p className="cartSubLabel">Total</p>
                                        <p className="cartSubLabel">{`${getFormattedPrice(pricer.total)} `}</p>
                                    </div>
                                </div>
                            </div>

                            <div
                                style={{
                                    padding: "20px",
                                    backgroundColor: "#FFFFFF",
                                    marginTop: "16px",
                                }}
                            >
                                <p className="addGro">
                                    Add <span style={{ color: "#CF1F1F" }}>{`  ${getFormattedPrice(minAmmount - pricer.total)} `} </span> of
                                    groceries to hit Minimum Order Value
                                </p>
                                <button className="emptyCartBtn" onClick={() => {
                                    setOpenCart(false)
                                }} >Keep Shopping</button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                style={{ backgroundColor: "#F5F5F5", padding: "8px 0px 8px 0px" }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        backgroundColor: "#F0DFD2",
                                        padding: "12px",
                                    }}
                                >
                                    <img style={{ paddingLeft: "22px" }} src={ship} />

                                    <p
                                        style={{ marginLeft: "14px" }}
                                        className="emptyCartDeliveryPara"
                                    >
                                        {` Delivery on ${moment(nextDate).format("DD MMMM")} `}
                                    </p>
                                </div>
                            </div>
                            <CartSubtotal totalPrice={pricer.total} isEdit={editMode} deliveryPrice={deliveryPrice} price={pricer} />
                            <div
                                style={{
                                    padding: "20px",
                                    backgroundColor: "#FFFFFF",
                                    marginTop: "16px",
                                }}
                            >
                                <button className="emptyCartBtn" onClick={() => {
                                    setOpenCart(false)
                                }}>Keep Shopping</button>

                                {!editMode ? <button
                                    className="emptyCartBtn"
                                    style={{
                                        backgroundColor: "#026d60",
                                        color: "#FFFFFF",
                                        marginTop: "4px",
                                        marginBottom: "12px",
                                    }}
                                    onClick={create}
                                >
                                    Finish up
                                </button> :
                                    <button
                                        className="emptyCartBtn"
                                        style={{
                                            backgroundColor: updateBtnDisabled ? "lightgray" : "#026d60",
                                            color: "#FFFFFF",
                                            marginTop: "4px",
                                            marginBottom: "12px",
                                        }}
                                        onClick={update}
                                        disabled={updateBtnDisabled}
                                    >
                                        Update order
                                    </button>}
                                <p className="cartReadyText">
                                    {`Ready to Deliver on ${moment(nextDate).format("DD MMMM")} `}
                                </p>
                            </div>
                        </>
                    )
                ) : null}
                <CartBottom />
            </div>
        </div>
    );
}

export default CartSidebar;
