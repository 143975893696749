import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import $ from "jquery";
import { useDispatch } from "react-redux";
import { setSelectedProduct, addItemOnCart } from "../redux/redux/products/productActions";
import { getFormattedPrice } from "../utils";

function ModalItem() {
	const dispatch = useDispatch();

	const [count, setCount] = useState(1);

	const { selectedProduct } = useSelector(
		(state) => ({
			selectedProduct: state.products.selectedProduct
		}),
		shallowEqual
	);

	function clearState() {
		setCount(1);
		dispatch(setSelectedProduct(null));
	}

	function addCount(type) {
		let currCount = count;
		if (type == 'add') {
			currCount++
			setCount(currCount);
		} else {
			if (currCount <= 1) { return; }
			currCount--
			setCount(currCount);
		}
	}

	function addItem() {
		dispatch(addItemOnCart(selectedProduct, count));
		setCount(1);

		setTimeout(() => {
			$(".modal-container").removeClass("modal-container-open");
			$("body").removeClass("modal-open");
			$(".modal-content-wrap").css("display", "none");
			$(".modal-container").css("display", "none");
		}, 200);
	}

	return (
		<div
			id="modal-item"
			className="modal-content-wrap modal-content-wrap-payment"
		>
			<div className="modal-appoint-righr is100">
				<div className="modal-title-wrap ml-0">
					<div className="modal-title">Product Detail</div>
					<div className="fh-100-2 mt20" />
				</div>
				{selectedProduct && <div className="moda-content-main">
					<div className="account-block-content is--modal">
						<a
							href="#"
							className="product-card is--cart is--suggest updatecart--card w-inline-block"
						>
							<div className="fh-100">
								<div className="product-card-content is--cart">
									<img
										src={`${selectedProduct["image"]}`}
										loading="lazy"
										sizes="100vw"
										srcSet={`${selectedProduct["image"]} 500w, ${selectedProduct["image"]} 800w, ${selectedProduct["image"]} 1080w, ${selectedProduct["image"]} 3300w`}
										alt
										className="product-card-image is--cart"
									/>
									<div className="product-card-title is--cart">
										{selectedProduct["name"]}
									</div>
									<div className="flex-h mt-10 is--cart">
										<div className="product-card-title is--qty is--cart">
											{selectedProduct["short_description"]}
										</div>
										<div className="product-card-title is--qty is--cart">
											{selectedProduct["units_of_measure"]["name"]}
										</div>
									</div>
								</div>
								<div className="product-card-pricerow is--cart">
									<div className="flex-v">
										{selectedProduct["product_discounts"] && <div className="product-card-offertag is--cart">{selectedProduct["product_discounts"]["discount_percentage"]}% OFF</div>}
										{selectedProduct["product_discounts"] && <div className="product-card-price is--cart"><p style={{ color: 'lightgray', fontSize: 14 }}><del>{getFormattedPrice(selectedProduct["price"])}</del></p></div>}
										{selectedProduct["product_discounts"] && <div className="product-card-price is--cart">{getFormattedPrice(selectedProduct["price"] - ((selectedProduct.price / 100) * selectedProduct["product_discounts"].discount_percentage))}</div>}
										{!selectedProduct["product_discounts"] && <div className="product-card-price is--cart">{getFormattedPrice(selectedProduct["price"])}</div>}
									</div>
								</div>
							</div>
						</a>
						<div className="product-card-title is--qty product-detail-text">
							{selectedProduct["full_description"]}
						</div>
						<div className="aisle-navigation is-middle">
							<div className="flex-h is-100 space">
								<div className="editorder-fh-wrap">
									<div className="plusminus mt0 w-embed">
										<div className="number">
											<button className="minus" onClick={() => addCount('minus')}>-</button>
											<input type="number" defaultValue={1} value={count} onChange={(e) => {
												if (!isNaN(e.target.value) && e.target.value > 0 && e.target.value < 100) {
													setCount(e.target.value);
												} else {
													setCount(1);
												}
											}} />
											<button className="plus" onClick={() => addCount('add')}>+</button>
										</div>
										<style
											dangerouslySetInnerHTML={{
												__html:
													"\n\t\t.minus, .plus{\n    /* height: 20px; */\n    background: #fbaf3f;\n    border-radius: 4px;\n    padding: 8px 10px 8px 10px;\n    /* border: 1px solid #ddd; */\n    display: inline-block;\n    font-size: 19px;\n    vertical-align: middle;\n    text-align: center;\n\t\t}\n\t\t.number input{\n\t\t\t    height: 37px;\n    width: 83px;\n    text-align: center;\n    font-size: 20px;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    display: inline-block;\n    vertical-align: middle;\n      }\n      "
											}}
										/>
									</div>
								</div>
								<button onClick={addItem} className="btnblock w-inline-block">
									<div className="button__large is--coloryellow is-100 is--aisle is--next">
										Add to Cart
									</div>
								</button>
							</div>
						</div>
					</div>
				</div>}
				<a href="#" id="item-modal" className="close-modal w-inline-block" onClick={clearState}>
					<img src="/images/times.svg" loading="lazy" alt />
				</a>
			</div>
		</div>
	);
}

export default ModalItem;
