import axios from "axios";
import { Auth } from "aws-amplify";

export async function setupAxios() {
    axios.interceptors.request.use(async function (config) {
        config.headers['X-Api-Key'] = process.env.REACT_APP_API_KEY;
        config.baseURL = process.env.REACT_APP_BASE_API_URL;

        try {
            const session = await Auth.currentSession();
            config.headers.Authorization = session.getIdToken().jwtToken;
            return await Promise.resolve(config);
        } catch {
            return await Promise.resolve(config);
        }
    })
}