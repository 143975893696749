import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/css/normalize.css";
//import "./assets/css/webflow.css";
//import "./assets/css/kiondo.webflow.css";
import "./index.css";
import "./assets/css/custom.css";
import "./assets/css/customStyles.css";

//import "./assets/css/kiondo-ce1f538674ec0369b435780fc3c8c8b3.webflow.css"
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
