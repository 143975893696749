import React, { useEffect, useState } from "react";
import { Suspense } from "react";
import { Route, Redirect } from "react-router";
import routes from "../dashRoutes";
import Loader from "./Loader";
import ShopModal from "./ShopModal";
import NavigationShop from "./NavigationShop";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import {
	getMinimumDateAndAmount, getCartProducts,
} from "../api";
import {
	selectDeliveryDates,
	setCoupon,
	setMinimumAmountAndData,
	setProductsFromLocal,
} from "../redux/redux/products/productActions";
import useWindowDimensions from "../hooks/useWindowDimensions";
import MobileHeaderLogin from "./MobileHeaderLogin";
import MobileMenuLogin from "./MobileMenuLogin";

const Dashboard = () => {

	const dispatch = useDispatch();
	const { height, width } = useWindowDimensions();

	useEffect(() => {
		initLoad();
	}, []);

	const { userOrder } = useSelector(
		(state) => ({
			userOrder: state.userOrder.userOrder
		}),
		shallowEqual
	);

	useEffect(() => {
		const savedCart = localStorage.getItem("k_cart");
		if (savedCart) {
			const cartFromLocal = JSON.parse(savedCart);
			if (cartFromLocal.length > 0) {
				getCartProducts(cartFromLocal).then((result) => {
					if (result.data) {
						dispatch(setProductsFromLocal(result.data));
					}
				});
			}
		}
	}, []);

	useEffect(() => {
		if (userOrder) {
			if (userOrder.user_coupons.length > 0) {
				if (userOrder.details) {
					let usedCouponForDeliveryDate = userOrder.user_coupons.find(f => f.delivery_date_fk == userOrder.details.id);

					if (usedCouponForDeliveryDate) {
						dispatch(setCoupon({ ...usedCouponForDeliveryDate.order_coupons, couponUsed: true }));
					}
				} else {
					dispatch(setCoupon(userOrder.user_coupons[0].order_coupons));
				}
			}

			if (userOrder.deliveryDates) {
				userOrder.deliveryDates.forEach(e => {
					dispatch(selectDeliveryDates(e.delivery_schedule_fk));
				})
			}
		}
	}, [userOrder]);

	async function initLoad() {
		const categoriesResult = await getMinimumDateAndAmount();
		dispatch(setMinimumAmountAndData(categoriesResult.data, categoriesResult.orderExist));
	}

	if (!userOrder) {
		return <Loader />;
	}

	return (
		<>
			{!userOrder.details && (
				<Redirect exact from="/dashboard" to="/dashboard/build-a-box" />
			)}

			{userOrder.details && (
				<Redirect exact from="/dashboard" to="/dashboard/upcomingbox" />
			)}

			{routes.map((route, i) => (
				<Route
					key={i}
					path={route.path}
					exact={route.exact}
					component={(props) => (
						<Suspense fallback={<Loader />}>
							<div className="section-dashboard">
								<div className="w-embed">
									<style
										dangerouslySetInnerHTML={{
											__html:
												'\n.header__light .headerlogo__text{\ncolor:#026D60;\n}\n.header__light .headercta.is--filled{\nbackground-color:#026D60;\nborder-color:#026D60;\n}\n.header__light .headercta.is--outline{\nborder-color:black;\ncolor:black;\n}\n.header__light .headermenu__link{\ncolor:black;\n}\n.header__light .footer__copylink{\ncolor:black !important;\nopacity:1;\n}\n.btn-shine::after {\n    top: 0;\n    display: block;\n    content: "";\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    -webkit-transform: translateX(-100%);\n    transform: translateX(-100%);\n    background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(rgba(255, 255, 255, .5)), to(transparent));\n    background: linear-gradient(\n90deg, transparent, rgba(255, 255, 255, .5), transparent);\n    -webkit-animation: loading .4s infinite;\n    animation: loading 1s infinite;\n}\n      @keyframes loading {\n        100% {\n          -webkit-transform: translateX(100%);\n          transform: translateX(100%)\n        }\n      }\n.slick-next, .slick-prev {\n    width: 40px;\n    height: 40px;\n    border-radius: 50%;\n    background-color: #fff;\n    box-shadow: 0 2px 8px 0 rgb(51 60 63 / 22%);\n    z-index: 11;\n}\n.overflow-y{\noverflow-y:auto !important;\n}\n.suggest-grid .slick-next, .suggest-grid .slick-prev {\n    width: 40px;\n    height: 40px;\n    border-radius: 50%;\n    background-color: #fbaf3f;\n    }\n    .suggest-grid .slick-disabled{\n    display:none !important;\n    }\n',
										}}
									/>
								</div>

								{width >= 480 ? (
									<NavigationShop />
								) : (
									<>
										<MobileHeaderLogin />
										<MobileMenuLogin />
									</>
								)}
								<div>
									<route.component {...props} />
								</div>
							</div>
						</Suspense>
					)}
				/>
			))}
			<ShopModal />
		</>
	);
};

export default Dashboard;
