import { createSlice } from "@reduxjs/toolkit";

const initialValues = {
    userToken: null
};

export const authSlice = createSlice({
    name: "auth",
    initialState: initialValues,
    reducers: {
        stopCall: (state, action) => {
            state.error = null;
            state.listLoading = false;
            state.actionsLoading = false;
        },
        setUserToken: (state, action) => {
            state.userToken = action.payload;
        },
    }
});
