import React, { useState } from "react";
import { sendCustomerFeedback } from "../api/customers";
import $ from "jquery"
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export function SendFeedback({ data, showAddToOrder = true }) {

	const dispatch = useDispatch();
	const [text, setText] = useState('');

	async function sendFeedback() {
		try {
			if (text) {
				const result = await sendCustomerFeedback(text);
				toast.success("Your feedback is received!");

				setText('');

				$(".modal-container").removeClass("modal-container-open");
				$("body").removeClass("modal-open");
				$(".modal-content-wrap").css("display", "none");
				$(".modal-container").css("display", "none");
			}

		} catch (error) {
			toast.error("Some thing went wrong");
			console.log(error);
		}
	}

	return (
		<div
			id="modal-item-send-feedback"
			className="modal-content-wrap modal-content-wrap-payment"
		>
			<div className="modal-appoint-righr is100">
				<div className="modal-title-wrap ml-0">
					<div className="modal-title">Send feedback</div>
					<div className="fh-100-2 mt20" />
				</div>

				<div className="moda-content-main">
					<div className="account-block-content is--modal">

						<label htmlFor="date-string">Write down your feedback or product suggestion</label>
						<textarea rows="4" cols="35"
							onChange={inp => {
								setText(inp.target.value);
							}}
						></textarea>
						<div className='flex-h' style={{ marginTop: 50 }}>
							<button className='button__large is--coloryellow is-100 is--aisle is--next' style={{ width: '100%' }} onClick={() => sendFeedback()}>
								Send
							</button>
						</div>
					</div>
				</div>
				<a href="#" id="modal-item-send-feedback" className="close-modal w-inline-block">
					<img src="/images/times.svg" loading="lazy" alt />
				</a>
			</div>
		</div>
	);

} 
