import React from "react";
import second from "./images/cover__image_kio.png";
const EmptyCart = () => {
  return (
    <>
      <div style={{ backgroundColor: "#FFEFD7" }}>
        <div style={{display:"flex",justifyContent:"center"}}>
          <img src={second} />
        </div>

        <div style={{padding:"22px"}}>
        <h1 className="emptyCartHeading">Your cart is empty</h1>
        <p className="emptyCartPara" style={{marginBottom:"0px"}}>
          Shop our inventory for the week. Any Ready to Ship items will be
          charged at the end of your shopping window.
        </p>
      </div>
      </div>
    </>
  );
};

export default EmptyCart;
