import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import cartIcon from "../assets/icons/cartIcon.svg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setUserAuth } from "../redux/redux/login/authActions";
import $ from "jquery";
import { Link, NavLink } from "react-router-dom";
import { Auth } from "aws-amplify";
import CartSidebar from "./CartSidebar";
import vector from "../assets/icons/Vector.svg";

export function NavigationShop() {
    const [openCart, setOpenCart] = useState(false);
    const [openDrop, setOpenDrop] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    async function logOut() {
        localStorage.removeItem("k_auth", null);
        dispatch(setUserAuth(null));

        await Auth.signOut();
    }

    const { cart } = useSelector(
        (state) => ({
            cart: state.products.cart,
        }),
        shallowEqual
    );
    const handleCLick = () => {
        setOpenCart(true);
    };


    function sendFeedback() {
        setTimeout(() => {
            $(".modal-content-wrap").css("display", "none");
            const modalId = "modal-item-send-feedback";
            $(".modal-container").css("display", "flex");
            $("#" + modalId).css("display", "flex");
            $(".modal-container").addClass("modal-container-open");
            $("body").addClass("modal-open");
        }, 200);
    }

    return (
        <>
            <div className="header_navigation is-shop">
                <div className="main_dashboardcontainer">
                    <div className="header__inner header__light">
                        <a href="#" className="headerlogo w-inline-block">
                            <div className="headerlogo__text">Kiondo Market</div>
                        </a>
                        <div className="header__ctawrap">
                            <Link
                                className="headingNav removeLine"
                                to="/dashboard/shop"
                            >
                                Shop
                            </Link>

                            <div style={{ position: "relative" }}>
                                <button style={{ margin: "0px 15px 0px 15px", backgroundColor: 'transparent' }} className="headingNav" onClick={() => {
                                    setOpenDrop(!openDrop);
                                }}>
                                    My Account
                                    <img
                                        alt="vector"
                                        src={vector}
                                        style={{ marginLeft: 5 }}
                                        onClick={() => {
                                            setOpenDrop(!openDrop);
                                        }}
                                    />
                                </button>
                                {openDrop ? (
                                    <>
                                        <div
                                            style={{
                                                position: "absolute",
                                                left: "30px",
                                                top: "30px",
                                                backgroundColor: "#FFFFFF",
                                                width: "100%",
                                                // minWidth: "120px",
                                                padding: "16px",
                                            }}
                                        >
                                            <div className="flexColumn">
                                                <NavLink
                                                    className="pb8 removeLine headingSM"
                                                    activeClassName="selected"
                                                    to="/dashboard/upcomingbox"
                                                >
                                                    Upcoming Box
                                                </NavLink>
                                                <NavLink
                                                    className="pb8 removeLine headingSM"
                                                    activeClassName="selected"
                                                    to="/dashboard/myorders"
                                                >
                                                    My Orders
                                                </NavLink>
                                                <NavLink
                                                    className="pb8 removeLine headingSM"
                                                    activeClassName="selected"
                                                    to="/dashboard/myaccount"
                                                >
                                                    Manage Account
                                                </NavLink>
                                                <NavLink
                                                    className="pb8 removeLine headingSM"
                                                    activeClassName="selected"
                                                    to="/dashboard/referrals"
                                                >
                                                    Referrals
                                                </NavLink>
                                            </div>
                                        </div>

                                    </>
                                ) : null}
                            </div>

                            <button
                                style={{
                                    backgroundColor: "transparent",
                                    margin: '0px 15px 0px 0px',
                                    borderColor: 'transparent',
                                    maxHeight: 45,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                                onClick={sendFeedback}
                            >
                                Send feedback
                            </button>

                            <button
                                style={{
                                    backgroundColor: "rgba(251, 175, 63, 0.16)",
                                    padding: '15px',
                                    border: '1px solid #000',
                                    borderRadius: 5,
                                    maxHeight: 45,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                                onClick={handleCLick}
                            >
                                {cart.length}
                                <img alt="cartIcon" src={cartIcon} style={{ marginLeft: 10 }} onClick={handleCLick} />
                            </button>


                            <button
                                onClick={logOut}
                                className="headercta is--filled w-button"
                            >
                                Log out
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {openCart && (
                <CartSidebar setOpenCart={setOpenCart} openCart={openCart} />
            )}
        </>
    );
}

export default NavigationShop;
