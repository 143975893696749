import React, { useState } from "react";
import moment from "moment";
import { pauseSubscription } from "../api/customers";
import $ from "jquery"
import { useDispatch } from "react-redux";
import { getUserBox } from "../api/orders";
import { setUserOrder } from "../redux/redux/orders/orderActions";

function PauseSubscription({ data, showAddToOrder = true }) {

	const dispatch = useDispatch();

	const [selectedDate, setPauseDate] = useState(moment().add(2, 'days').format("YYYY-MM-DD"));

	function getMinimumDate() {
		return moment().add(2, 'days').format("YYYY-MM-DD");
	}

	function getMaxDate() {
		return moment().add(1, "year").format("YYYY-MM-DD");
	}

	async function pauseSubscr() {
		await pauseSubscription(selectedDate);
		const result = await getUserBox()
		if (result.data) {
			dispatch(setUserOrder(result.data));
		}

		$(".modal-container").removeClass("modal-container-open");
		$("body").removeClass("modal-open");
		$(".modal-content-wrap").css("display", "none");
		$(".modal-container").css("display", "none");
	}

	return (
		<div
			id="modal-item-pause-subscription"
			className="modal-content-wrap modal-content-wrap-payment"
		>
			<div className="modal-appoint-righr is100">
				<div className="modal-title-wrap ml-0">
					<div className="modal-title">Pause Subscription</div>
					<div className="fh-100-2 mt20" />
				</div>

				<div className="moda-content-main">
					<div className="account-block-content is--modal">

						<label htmlFor="date-string">Pause subscription until</label>
						<input
							className="form__field w-input"
							type="date"
							id="date-string"
							min={getMinimumDate()}
							max={getMaxDate()}
							defaultValue={getMinimumDate()}
							onChange={inp => {
								setPauseDate(inp.target.value);
							}}
						/>
						<div className='flex-h' style={{ marginTop: 50 }}>
							<button className='button__large is--coloryellow is-100 is--aisle is--next' style={{ width: '100%' }} onClick={() => pauseSubscr()}>
								Pause
							</button>
						</div>
					</div>
				</div>
				<a href="#" id="modal-item-pause-subscription" className="close-modal w-inline-block">
					<img src="/images/times.svg" loading="lazy" alt />
				</a>
			</div>
		</div>
	);

}

export default PauseSubscription;
