import React from "react";

class ModalPayment extends React.Component {
  render() {
    return (
      <div
        id="modal-payment"
        className="modal-content-wrap modal-content-wrap-payment"
      >
        <div className="modal-appoint-righr is100">
          <div className="modal-title-wrap ml-0">
            <div className="modal-title">Update Payment Card</div>
            <div className="fh-100-2 mt20" />
          </div>
          <div className="moda-content-main">
            <div className="account-block-content is--modal">
              <div className="profile-details-form w-form">
                <form
                  id="email-form"
                  name="email-form"
                  data-name="Email Form"
                  className="profile-detail-inner"
                >
                  <div className="input__wrapper mb-0 width-45 is--100">
                    <div className="account-form-label">Card Number</div>
                    <div className="input-form-wrap-field">
                      <input
                        type="text"
                        className="form__field padding-none w-input"
                        maxLength={256}
                        name="name-2"
                        data-name="Name 2"
                        placeholder="**** **** **** ****"
                        id="name-2"
                        required
                      />
                    </div>
                  </div>
                  <div className="input__wrapper mb-0 width-45">
                    <div className="account-form-label">MM/YY</div>
                    <div className="input-form-wrap-field">
                      <input
                        type="text"
                        className="form__field padding-none w-input"
                        maxLength={256}
                        name="MONTH"
                        data-name="MONTH"
                        placeholder="MM/YYYY"
                        id="MONTH"
                        required
                      />
                    </div>
                  </div>
                  <div className="input__wrapper mb-0 width-45">
                    <div className="account-form-label">CVC</div>
                    <div className="input-form-wrap-field">
                      <input
                        type="text"
                        className="form__field padding-none w-input"
                        maxLength={256}
                        name="name-2"
                        data-name="Name 2"
                        placeholder="3 digit CVC"
                        id="name-2"
                        required
                      />
                    </div>
                  </div>
                  <div className="input__wrapper mb-0 width-45 is--100">
                    <div className="account-form-label">Card Holder Name</div>
                    <div className="input-form-wrap-field">
                      <input
                        type="text"
                        className="form__field padding-none w-input"
                        maxLength={256}
                        name="name-2"
                        data-name="Name 2"
                        placeholder="+81 823-3232-2322"
                        id="name-2"
                        required
                      />
                      <a href="#" className="user-email">
                        Change Contact
                      </a>
                    </div>
                  </div>
                </form>
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
              <div className="button__wrap">
                <a
                  href="#"
                  className="button__large is--coloryellow is-100 w-button"
                >
                  Save Card
                </a>
              </div>
            </div>
          </div>
          <a href="#" className="close-modal w-inline-block">
            <img src="/images/times.svg" loading="lazy" alt />
          </a>
        </div>
      </div>
    );
  }
}

export default ModalPayment;
