import React from "react";
import { Link } from "react-router-dom";

 function NavigationBar (props) {

    return (
      <>
    
      <div className="header_navigation" style={{backgroundColor:`${props.backgroundColor}`}}>
        <div className="container">
          <div className="header__inner">
            <Link to="/" className="headerlogo w-inline-block">
              <div className="headerlogo__text" style={{color:`${props.color}`}}>Kiondo Market</div>
            </Link>
            <div className="headermenu__container" >
              <Link to="/ourstory" className="headermenu__link" style={{color:`${props.color}`}}>
                Kiondo Story
              </Link>
              <Link to="/sourcing" className="headermenu__link" style={{color:`${props.color}`}}>
                Our Sourcing
              </Link>
			  <Link to="/contact" className="headermenu__link" style={{color:`${props.color}`}}>
                Contact Us
              </Link>
              <Link to="/faq" className="headermenu__link" style={{color:`${props.color}`}}>
                FAQs
              </Link>
            </div>
            <div className="header__ctawrap">
              <Link to="/login" className="headercta is--outline w-button">
                Log In
              </Link>
              <Link to="/register" className="headercta is--filled w-button">
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }


export default NavigationBar;
