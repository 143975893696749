import React, { useEffect, useState } from "react";

export default function ConfirmationPage() {

    const [email, setEmail] = useState(null);

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const queryEmail = query.get("email");
        
        setEmail(queryEmail);
    }, []);

    return <>
        <div data-component="ContactPage" className="main__container">
            <div className="section section-confirmation">
                <div className="container">
                    <div className="confirmation-box">
                        <div className="confirmation-inner"><div className="lottie-wrap" />
                            <h2 className="is--center">Verify Your Email</h2>
                            <div className="button__wrap is--100" />
                            <p className="confirmation-text"><strong>We have sent an email to </strong>
                                <a href="#">
                                    <strong className="text-link text-linkbig">{email ? email : ''}</strong>
                                </a>
                                <br />
                                <strong>Please follow the link and verify your email.</strong></p>
                            <p className="paragraph mt-20 is--small is-descform">By providing your email address, you agree to our <a href="#" className="blackhighlight">Terms of Service</a> and 
                                <a href="#" className="blackhighlight">Privacy Policy</a>.</p>
                        </div>
                    </div>
                </div>
                <img src="https://assets.website-files.com/610e724a154be687bd1be332/616d7b4c4fbea03afdd196c1_png-img.png" loading="lazy" sizes="(max-width: 767px) 100vw, 600px" srcSet="https://assets.website-files.com/610e724a154be687bd1be332/616d7b4c4fbea03afdd196c1_png-img-p-500.png 500w, https://assets.website-files.com/610e724a154be687bd1be332/616d7b4c4fbea03afdd196c1_png-img-p-800.png 800w, https://assets.website-files.com/610e724a154be687bd1be332/616d7b4c4fbea03afdd196c1_png-img.png 960w" alt="" className="confirmation-bg" />
            </div>
            <div className="w-embed">
                <style dangerouslySetInnerHTML={{ __html: "\n.header__light .headerlogo__text{\ncolor:#026D60;\n}\n\n.header__light .headercta.is--filled{\nbackground-color:#026D60;\nborder-color:#026D60;\n}\n\n.header__light .headercta.is--outline{\nborder-color:black;\ncolor:black;\n}\n\n.header__light .headermenu__link{\ncolor:black;\n}\n\n.header__light .footer__copylink{\ncolor:black !important;\nopacity:1;\n}\n" }} />
            </div>
        </div>
    </>
}