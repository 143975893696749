import React from "react";

const HomePage = React.lazy(async () => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  return import("./components/HomePage");
});
const ContactPage = React.lazy(async () => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  return import("./components/ContactPage");
});
const FAQPage = React.lazy(async () => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  return import("./components/FaqPage");
});
const LoginPage = React.lazy(async () => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  return import("./components/Auth/LoginPage");
});
const ForgotPassword = React.lazy(async () => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  return import("./components/Auth/ForgotPassword");
});
const RegisterPage = React.lazy(async () => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  return import("./components/Auth/RegisterPage");
});
const StoryPage = React.lazy(async () => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  return import("./components/StoryPage");
});
const SourcingPage = React.lazy(async () => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  return import("./components/SourcePage");
});

const VerifyOTP = React.lazy(async () => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  return import("./components/ContactOtp");
});

const routes = [
  {
    path: "/contact",
    exact: true,
    component: ContactPage,
    name: "Contact"
  },
  {
    path: "/faq",
    exact: true,
    component: FAQPage,
    name: "FAQ"
  },
  {
    path: "/login",
    exact: true,
    component: LoginPage,
    name: "Login"
  },
  {
    path: "/forgot-password",
    exact: true,
    component: ForgotPassword,
    name: "ForgotPassowrd"
  },
  {
    path: "/ourstory",
    exact: true,
    component: StoryPage,
    name: "Story"
  },
  {
    path: "/register",
    exact: true,
    component: RegisterPage,
    name: "Register"
  },
  {
    path: "/sourcing",
    exact: true,
    component: SourcingPage,
    name: "Sourcing"
  },
  {
    path: "/verify-otp",
    exact: true,
    component: VerifyOTP,
    name: "Verify OTP"
  },
  {
    path: "/",
    exact: true,
    component: HomePage,
    name: "Home"
  },
];


export default routes;
