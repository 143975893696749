import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setUserAuth } from "../redux/redux/login/authActions";
import { Auth } from "aws-amplify";
import CartSidebar from "./CartSidebar";
export function MobileMenuLogin() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [openCart, setOpenCart] = useState(false);
  function logOut() {
    localStorage.removeItem("k_auth", null);
    dispatch(setUserAuth(null));
  }
  async function logOut() {
    localStorage.removeItem("k_auth", null);
    dispatch(setUserAuth(null));

    await Auth.signOut();
  }

  const { cart } = useSelector(
    (state) => ({
      cart: state.products.cart,
    }),
    shallowEqual
  );
  return (
    <>
      <div className="mobile__menu">
        <div className="header__ctawrap">
          <Link className="headermenu__link" to="/dashboard/shop">
            Shop
          </Link>
          <Link className="headermenu__link" to="/dashboard/upcomingbox">
            Upcoming Box
          </Link>
          <Link className="headermenu__link" to="/dashboard/myorders">
            My orders
          </Link>

          <Link className="headermenu__link" to="/dashboard/myaccount">
            My Account
          </Link>

          <Link className="headermenu__link" to="/dashboard/referrals">
            Referrals
          </Link>

          {/* <a
          className="headermenu__link"
          onClick={() => {
            setOpenCart(true);
          }}
        >
          {cart.length > 0 && (
            <div
              style={{
                padding: 4,
                marginRight: 4,
                backgroundColor: "white",
                color: "black",
              }}
            >
              {cart.length}
            </div>
          )}
          View Cart
        </a> */}

          <a href="#" onClick={logOut} className="headermenu__link">
            Log out
          </a>
        </div>
        {}
      </div>
      {/* {
      openCart && (
        <CartSidebar openCart={openCart} setOpenCart={setOpenCart}/>
      )
    } */}
    </>
  );
}

export default MobileMenuLogin;
