import NavigationBar from "./components/NavigationBar";
import MobileHeader from "./components/MobileHeader";
import FooterGreen from "./components/FooterGreen";
import { Route, Switch, useLocation } from "react-router";
import routes from "./routes";
import { Suspense, useEffect, useState } from "react";
import NavigationBarLight from "./components/NavigationBarLight";
import FooterLight from "./components/FooterLight";
import MobileMenuLogin from "./components/MobileMenu";
import CtaStripYellow from "./components/CtaStripYellow";
import Loader from "./components/Loader";
import Dashboard from "./components/Dashboard";
import EmailVerified from "./components/EmailVerify";
import ConfirmationPage from "./components/ConfirmationPage";

import { setupAxios } from "./setupAxios";
import { Redirect } from "react-router";
import { useDispatch, } from "react-redux";
import PreviewOrder from "./components/PreviewOrder";

import Amplify from "aws-amplify";
import { Auth, Hub, Logger } from "aws-amplify";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { fetchOrderPrice, fetchUserOrderAndPrice } from "./redux/redux/orders/orderActions";

Amplify.configure({
    aws_cognito_region: "eu-west-1",
    aws_user_pools_id: process.env.REACT_APP_POOL_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_WEB_CLIENT_ID,
});

export function App() {

    const location = useLocation();
    const params = new URLSearchParams(location.search)
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(true);
    const [isAuth, setAuth] = useState(false);
    const homebg = " #026D60";
    const sourcebg = "#ee9d2b";

    const storybg = "#f6f0e8";
    useEffect(() => {
        if (!location.pathname.includes("/dashboard")) {
            document
                .querySelector(".mobile__navigation-btn")
                ?.addEventListener("click", () => {
                    // add is--open to this element
                    document
                        .querySelector(".mobile__navigation-btn")
                        ?.classList.toggle("is--open");
                    // add overlow hidden to body
                    document.querySelector("body")?.classList.toggle("overflow-hidden");
                    document
                        .querySelector("body")
                        ?.classList.toggle("body-mobile-menu-active");
                    // mobile-header-active to the elements parent's parent
                    document
                        .querySelector(".mobile__navigation-btn")
                        .parentElement.parentElement?.classList.toggle(
                            "mobile-header-active"
                        );

                    // add mobile__menushow .mobile__menu
                    document
                        .querySelector(".mobile__menu")
                        ?.classList.toggle("mobile__menushow");
                });
        }
    }, []);

    useEffect(() => {
        if (!location.pathname.includes("/dashboard")) {
            // remove all classes with page--
            let list = document.querySelector("body").classList;
            for (let i of list) {
                if (i.includes("page--")) {
                    document.querySelector("body").classList.remove(i);
                }
            }
            document
                .querySelector(".mobile__navigation-btn")
                ?.classList.remove("is--open");
            // add overlow hidden to body
            document.querySelector("body").classList.remove("overflow-hidden");
            document
                .querySelector("body")
                ?.classList.remove("body-mobile-menu-active");
            // mobile-header-active to the elements parent's parent
            document
                .querySelector(".mobile__navigation-btn")
                ?.parentElement.parentElement.classList.remove("mobile-header-active");

            // add mobile__menushow .mobile__menu
            document
                .querySelector(".mobile__menu")
                ?.classList.remove("mobile__menushow");

            if (location.pathname === "/") {
                document.querySelector("body")?.classList.add("page--home");
            } else {
                // extract last part of the pathname
                const path = location.pathname.split("/").pop();
                document.querySelector("body")?.classList.add("page--" + path);
            }
        }
    }, [location]);

    useEffect(() => {
        onLoad();
    }, []);

    async function onLoad() {
        try {
            const ref = params.get('ref');
            if (ref) {
                localStorage.setItem("ref", ref);
            }

            const res = await Auth.currentAuthenticatedUser({
                bypassCache: true,
            });

            if (res) {
                setAuth(true);
                setLoader(false);
                await setupAxios();
                dispatch(fetchUserOrderAndPrice());
            }
        } catch (error) {
            setAuth(false);
            setLoader(false);
            setupAxios();
        }
    }

    useEffect(() => {
        const listener = async (data) => {
            switch (data.payload.event) {
                case "signIn":
                case "tokenRefresh":
                    setAuth(true);
                    dispatch(fetchUserOrderAndPrice());
                    break;
                case "signOut":
                case "signIn_failure":
                case "tokenRefresh_failure":
                    setAuth(false);
                    break;
            }
        };

        Hub.listen("auth", listener);
    }, []);

    if (loader) {
        return <Loader />;
    }

    return (
        <>
            <Switch>
                <Route path="/preview-order/:id" component={PreviewOrder} />
                <Route path="/verify-email" component={EmailVerified} />
                <Route path="/confirmation" component={ConfirmationPage} />

                {isAuth && <Route path="/dashboard" component={Dashboard} />}

                {isAuth && <Redirect to="dashboard" />}
                {!isAuth && (
                    <Route>
                        <MobileHeader />
                        <MobileMenuLogin />
                        <Switch>
                            <Route
                                path="/register"
                                render={(props, index) => (
                                    <NavigationBarLight
                                        key={index}
                                        backgroundColor={storybg}
                                        {...props}
                                    />
                                )}
                            />
                            <Route
                                path="/contact"
                                render={(props, index) => (
                                    <NavigationBarLight
                                        key={index}
                                        backgroundColor={sourcebg}
                                        {...props}
                                    />
                                )}
                            />
                            <Route
                                path="/sourcing"
                                render={(props, index) => (
                                    <NavigationBarLight
                                        key={index}
                                        backgroundColor={sourcebg}
                                        {...props}
                                    />
                                )}
                            />
                            <Route
                                path="/login"
                                render={(props, index) => (
                                    <NavigationBarLight
                                        key={index}
                                        backgroundColor={storybg}
                                        {...props}
                                    />
                                )}
                            />
                            <Route
                                path="/forgot-password"
                                render={(props, index) => (
                                    <NavigationBarLight
                                        key={index}
                                        backgroundColor={storybg}
                                        {...props}
                                    />
                                )}
                            />
                            <Route
                                path="/ourstory"
                                render={(props, index) => (
                                    <NavigationBarLight
                                        key={index}
                                        backgroundColor={storybg}
                                        {...props}
                                    />
                                )}
                            />
                            {/* <Route path='*' component={NavigationBar} /> */}
                            <Route
                                path="*"
                                render={(props) => (
                                    <NavigationBar backgroundColor={homebg} color="#fff" />
                                )}
                            />
                        </Switch>
                        <Switch>
                            {routes.map((route, i) => (
                                <Route
                                    key={i}
                                    path={route.path}
                                    exact={route.exact}
                                    component={(props) => (
                                        <Suspense fallback={<Loader />}>
                                            <route.component {...props} />
                                        </Suspense>
                                    )}
                                />
                            ))}

                            {!isAuth && <Redirect to="/" />}
                        </Switch>
                        <CtaStripYellow />
                        <Switch>
                            <Route path="/register" component={FooterLight} />
                            <Route path="/login" component={FooterLight} />
                            <Route path="/contact" component={FooterLight} />
                            <Route path="/sourcing" component={FooterLight} />
                            <Route path="/ourstory" component={FooterLight} />
                            <Route path="/faq" component={FooterLight} />
                            <Route path="*" component={FooterGreen} />
                        </Switch>
                    </Route>
                )}
            </Switch>
            <ToastContainer />
        </>
    );
}
