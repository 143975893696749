import React from "react";
import deliver from "../assets/icons/deliver.svg";
const DeliverTo = ({ userInfo }) => {
  return (
    <>
      <h1 className="headingLG marginBottom16">Delivery Address</h1>
      <div className="whiteBackground p32 marginBottom48 planStracture">
        <img alt="deliver" src={deliver} />
        <div className="flexColumn">
          <p className="paraMD">Acc no: <span>{userInfo?.accNumber}</span></p>
          <p className="paraMD">Full name: <span>{userInfo?.name}</span></p>
          <p className="paraMD">Phone No: <span>{userInfo?.phoneNo}</span></p>
          <p className="paraMD">Region: <span>{userInfo?.region}</span></p>
          <p className="paraMD">Location/Street: <span>{userInfo?.area}</span></p>
          <p className="paraMD">House No: <span>{userInfo?.houseNo}</span></p>
          <p className="paraMD">Additional Info: <span>{userInfo?.extra_info}</span></p>
        </div>
      </div>
    </>
  );
};

export default DeliverTo;
