import { useState, useEffect } from "react";
import { updateAccountDetails } from "../api";
import $ from "jquery";

function ModalOtp() {
	const [phone, setPhone] = useState(null)
	const [otpCode, setOTPCode] = useState(null)
	const [loginResponse, setLoginResponse] = useState(null);
	const [sendedOTP, setSendedOTP] = useState(false);
	const [phoneExist, setPhoneExist] = useState(false);

	useEffect(() => {
		$("a[data-trigger=modal]").on("click", function () {
			setPhone('');
			setOTPCode('');
			setLoginResponse(null);
			setSendedOTP(false);
		});
	}, [])

	async function sendOTP() {

		if (sendedOTP) {
			return true;
		}

		setLoginResponse(null);

		if (phone) {
			// const result = await getOTPToken({ phone: phone });

			// if (result.message == "User already exist!") {
			// 	setPhoneExist(true);
			// 	setTimeout(() => {
			// 		setPhoneExist(false);
			// 	}, 5000);
			// } else {
			// 	setSendedOTP(true);

			// 	setTimeout(() => {
			// 		setSendedOTP(false);
			// 	}, 5000);
			// }
		}
	}

	async function updateUser() {
		if (loginResponse) {
			return true;
		}

		setLoginResponse(null);
		if (otpCode) {

			// const result = await validatePhoneOTP({ phone: phone, code: otpCode });

			// if (result.success) {
			// 	const res = await updateAccountDetails({ phone, phoneOnly: true }, false);

			// 	if (res.success) {
			// 		setLoginResponse('success');

			// 		setTimeout(() => {
			// 			$("#close_btn")[0].click();
			// 		}, 2000);

			// 	} else {
			// 		setLoginResponse('error');
			// 	}
			// } else {
			// 	setLoginResponse('error');
			// }
		}
	}

	return (
		<div
			id="modal-otp"
			className="modal-content-wrap modal-content-wrap-payment is-otp"
		>
			<div className="modal-appoint-righr is100">
				<div className="modal-title-wrap ml-0">
					<div className="modal-title">Verify your Number</div>
					<div className="fh-100-2 mt20" />
				</div>
				<div className="moda-content-main">
					<div className="account-block-content is--modal">
						<div className="profile-details-form w-form">
							<div
								className="profile-detail-inner"
							>
								<div className="input__wrapper mb-0 width-45 is--100">
									<div className="account-form-label">Phone number</div>
									<div className="input-form-wrap-field">
										<input
											type="text"
											className="form__field padding-none w-input"
											maxLength={256}
											name="name-2"
											data-name="Name 2"
											placeholder="+254722000000"
											id="name-2"
											required
											value={phone}
											onChange={(e) => {
												setLoginResponse(null);
												setPhone(e.target.value)
											}}
										/>

										<a href="#" onClick={sendOTP} className="user-email">
											Send OTP
										</a>
									</div>
									{sendedOTP && <div className="w-form-done" style={{ display: 'block', marginBottom: 5, marginTop: '5px' }}>
										<div>The verification code have been sent to your phone number!</div>
									</div>
									}

									{phoneExist && <div className="w-form-fail" style={{ display: 'block', marginBottom: 5 }}>
										<div>Oops! Entered phone number already exist!</div>
									</div>
									}
								</div>
								<div className="input__wrapper mb-0 width-45 is--100">
									<div className="account-form-label">Enter 4 digit code</div>
									<div className="input-form-wrap-field">
										<input
											type="text"
											className="form__field padding-none w-input"
											maxLength={4}
											name="name-2"
											data-name="Name 2"
											placeholder="X X X X "
											id="name-2"
											required
											value={otpCode}
											onChange={(e) => {
												setLoginResponse(null);
												setOTPCode(e.target.value)
											}}
										/>
									</div>
								</div>
							</div>

							{loginResponse === 'success' && <div className="w-form-done" style={{ display: 'block', marginBottom: 5 }}>
								<div>Thank you! Your submission has been received!</div>
							</div>
							}

							{loginResponse === 'error' && <div className="w-form-fail" style={{ display: 'block', marginBottom: 5 }}>
								<div>Oops! Something went wrong while submitting the form.</div>
							</div>
							}
						</div>

						<div className="button__wrap">
							<a
								onClick={updateUser}
								href="#"
								className="button__large is--coloryellow is-100 w-button"
							>
								Update Number
							</a>
						</div>
					</div>
				</div>
				<a href="#" id="close_btn" className="close-modal w-inline-block">
					<img src="/images/times.svg" loading="lazy" alt />
				</a>
			</div>
		</div>
	);
}

export default ModalOtp;
