import React from "react";
import { Link } from "react-router-dom";
import SubscribeFooter from "./FooterSubscribe";

class FooterLight extends React.Component {
  render() {
    return (
      <footer className="footer footer-light">
        <div className="lgg-container">
          <div className="footer-inner">
            <div className="footer-upper">
              <div className="footer-uright">
                <div className="footer-left">
                  <Link to="/" className="headerlogo is--footer w-inline-block">
                    <div className="headerlogo__text is--dark">
                      Kiondo Market
                    </div>
                  </Link>
                  <div className="footer-content-grid">
                    <div className="footerleft-content">
                      <div className="footer-menu-title mb-20 is--black">
                        Pickup/Retail Center
                      </div>
                      <div className="footertext is--dark">
                        Kihingo, Kirawa Rd, Kitisuru West
                        <br />
                      </div>
                      <div className="footertext is--dark">
                        sales@kiondo.market
                        <br />
                      </div>
                      <div className="footertext is--dark">
                        +254 780 514530
                        <br />
                      </div>
                    </div>
                    <div className="footerleft-content">
                      <div className="footer-menu-title mb-20 is--black">
                        Follow us on
                      </div>
                      <div className="flex-h">
                        <a
                          href="https://web.facebook.com/KiondoMarket"
                          className="footer-socialicon w-inline-block"
                        >
                          <img
                            src="images/facebook.svg"
                            loading="lazy"
                            alt
                            className="social-ing is-invert"
                          />
                        </a>
                        <a
                          href="https://www.instagram.com/kiondomarket"
                          className="footer-socialicon w-inline-block"
                        >
                          <img
                            src="images/instagram.svg"
                            loading="lazy"
                            alt
                            className="social-ing is-invert"
                          />
                        </a>
                        <a
                          href="https://twitter.com/KiondoMarket"
                          className="footer-socialicon w-inline-block"
                        >
                          <img
                            src="images/twitter.svg"
                            loading="lazy"
                            alt
                            className="social-ing is-invert"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                 <SubscribeFooter />
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom is--light">
          <div className="lgg-container lgg-container-footer">
            <div className="footer-copy-text is--dark">
              Copyright © Kiondo Market. All rights reserved
            </div>
            <div className="flex-h">
              <a href="https://www.kiondo.market/termsprivacy/terms.html" className="footer__copylink is-dark">
                Terms of Service
              </a>
              <Link to="/dashboard" className='footer__copylink'>
                Dashboard
              </Link>
              <a href="https://www.kiondo.market/termsprivacy/privacy.html" className="footer__copylink is-dark">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
        <img
          src="images/tree__green.svg"
          loading="lazy"
          alt
          className="footer--plant"
        />
        <div className="w-embed">
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n.header__light .headerlogo__text{\ncolor:#026D60;\n}\n.header__light .headercta.is--outline{\nborder-color:black;\ncolor:black;\n}\n.header__light .headermenu__link{\ncolor:black;\n}\n"
            }}
          />
        </div>
      </footer>
    );
  }
}

export default FooterLight;
