import { createSlice, current } from "@reduxjs/toolkit";

const initialValues = {
    selectedProduct: null,
    minDate: null,
    minAmmount: null,
    minDateId: null,
    cart: [],
    coupon: null,
    selectedDeliveryDates: []
};

export const productSlice = createSlice({
    name: "products",
    initialState: initialValues,
    reducers: {
        stopCall: (state, action) => {
            state.error = null;
            state.listLoading = false;
            state.actionsLoading = false;
        },
        selectedProductOnList: (state, action) => {
            state.selectedProduct = action.payload;
        },
        setMinimumAmountAndDate: (state, action) => {
            state.minDate = action.payload.minDate;
            state.minAmmount = action.payload.minAmmount;
            state.minDateId = action.payload.minDateId;
        },
        setCart: (state, action) => {
            state.cart = action.payload;
            localStorage.setItem(
                "k_cart",
                JSON.stringify(
                    action.payload.map((m) => {
                        return { id: m["id"], count: m["count"] };
                    })
                )
            );
        },
        setCoupon: (state, action) => {
            state.coupon = action.payload;
        },
        cartIncrement: (state, action) => {
            let id = action.payload;
            const found = state.cart.find((item) => item.id === id);
            found.count += 1;
        },
        cartDecrement: (state, action) => {
            let id = action.payload;
            const found = state.cart.find((item) => item.id === id);
            found.count -= 1;
        },
        testReducer: (state, action) => {
            // console.log("test reducer", current(state));
            // console.log("test reducer action", action);
        },
        setSelectedDeliveryDate: (state, action) => {
            if (action.payload) {
                const itemExist = state.selectedDeliveryDates.find(f => f == action.payload);

                if (itemExist) {
                    state.selectedDeliveryDates = state.selectedDeliveryDates.filter(f => f != action.payload)
                } else {
                    state.selectedDeliveryDates.push(action.payload)
                }
            }
        }
    },
});
// export const {cartIncrement,cartDecrement}=productSlice.actions
