import { fetchFamiliesAndRegions, getCartProducts } from "../../../api";
import { getOrderPrice, getUserBox } from "../../../api/orders";
import { clearCartItems, setProductsFromLocal } from "../products/productActions";
import { ordersSlice } from "./ordersSlice";
const { actions } = ordersSlice;

export const setUserOrder = (order) => dispatch => {
    dispatch(actions.setUserOrder(order));
};

export const fetchOrderPrice = () => async dispatch => {
    const result = await getOrderPrice();
    dispatch(actions.setOrderPrice(result.data));
};

export const fetchUserOrderAndPrice = () => async dispatch => {
    dispatch(clearCartItems());

    const price = await getOrderPrice();
    const result = await getUserBox();
    const currentProducts = await getCartProducts(null, true);
    const fAndRegions = await fetchFamiliesAndRegions();

    if (result.data && result.data.details) {
        dispatch(setEditMode(true))
    }

    if (fAndRegions && fAndRegions.deliveryPrice) {
        dispatch(actions.setDeliveryPrice(fAndRegions.deliveryPrice))
    }

    dispatch(setProductsFromLocal(currentProducts.data));
    dispatch(actions.setOrderPrice(price.data));
    dispatch(actions.setUserOrder(result.data));
};

export const setEditMode = (editMode) => dispatch => {
    dispatch(actions.setEditMode(editMode));
};
